@import '../variables.scss';

/*-------------General Style---------------------------------------*/
html{
	overflow-x:hidden !important;
	height: 100%;
	background: $blue;
}
body{
	font-weight: 600;
	font-size: 13px;
	height: 100%;
	width: 100%;
	margin: 0px;
	padding: 0px;
	text-align:center;
	background: $blue;
	color:$black;
	font-family: 'Nunito', sans-serif;
}
@-o-viewport {width: device-width;}
@-ms-viewport {width: device-width;}
@viewport {width: device-width;}
/*--------------Typography--------------------------------------*/
p {
	font-size: 13px;
	line-height: 24px;
	padding-bottom: 10px;
	font-weight:500;
	white-space: pre-line;
	color: $gray-dark;
}
blockquote {
	float:left;
	padding: 10px 20px;
	margin: 0 0 20px;
	font-size: 17.5px;
	border-left: 15px solid $gray;
	position:relative;
}
blockquote:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f10e";
	position:absolute;
	color:$gray-2;
	bottom:3px;
	font-size:43px;
	right:6px;
	opacity:0.4
}
blockquote p {
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	color:$gray-9;
}
/*------ loader-------------------------------------------------*/
.loader-wrap {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:100;
	background: $blue;
}
.pin , .ajax-loader {
	position:absolute;
	left:50%;
	top:50%;
	width:40px;
	height:40px;
	border:4px solid;
	border-radius:100%;
	margin:-20px 0 0 -20px;
}
.pulse  , .ajax-loader-cirle{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	border-radius:100%;
	animation: spin 1000ms linear infinite;
}
.pulse:before  , .ajax-loader-cirle:before{
	content:'';
	position:absolute;
	width:12px;
	height:4px;
	border-radius:4px;
	background:$white;
	left:50%;
	top:50%;
	margin:-2px 0 0 -12px;
}
 
@keyframes spin {
	to{
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/* ---------Content Styles--------------------------------------*/
#main{
	/* 	height:100%;
	width:100%;
	position:absolute;
	top:0;
	left:0;
	z-index:2; */
	/* opacity:0; */
}
.container{
	max-width: 1224px;
	width:92%;
	margin:0 auto;
	position: relative;
	z-index:5;
}
.big-container {
	max-width:1500px;
}
.small-container {
	max-width:1024px;
}
#wrapper {
	float:left;
	width:100%;
	position:relative;
	z-index:5;
	padding-top:118px;
}
#wrapper.home-wrap {
	padding-top:0;
}
.fl-wrap {
	float:left;
	width:100%;
	position:relative;
}
.no-bg {
	background:none;
}
.full-height {
	height:100%;
}
.full-width-wrap {
	width:100%;
}
.bg {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
	background-size: cover;
	background-attachment: scroll  ;
	background-position: center  ;
	background-repeat:repeat;
	background-origin: content-box;
}
.wave-bg  , .city-bg{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
	background:url(../../images/wave.png) repeat;
}
.respimg {
	width:100%;
	height:auto;
}
.overlay {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:$black;
	opacity:0.2;
	z-index:2
} 
.op1 {
	opacity:0.1;
}
 
.op3 {
	opacity:0.3;
}
.op7 {
	opacity:0.5 !important ;
}
.box-item {
	float:left;
	width:100%;
	position:relative;
	overflow:hidden;
}
.box-item:before {
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	opacity:0;
	z-index:2;
	background:rgba(51,51,51,0.41);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
.box-item .gal-link{
	position:absolute;
	width:40px;
	height:40px;
	line-height:40px;
	border-radius:100%;
	color:$white;
	font-size:8px;
	z-index:10;
	top:40%;
	left:50%;
	margin:-20px 0 0 -20px;
	opacity:0;
	box-shadow: 0px 0px 0px 9px rgba($white,0.4);
	transition: all 300ms linear;
	cursor: pointer;
}
.list-single-gallery .box-item {
	border-radius:6px;
}
.box-item:hover .gal-link {
	top:50%;
	opacity:1;
}
.box-item:hover:before {
	opacity:1;
}
.fw-separator {
	float:left;
	width:100%;
	margin:25px 0;
	height:1px;
	background:$gray;
}
.no-padding {
	padding:0 !important;
}
/*-------------Button---------------------------------------*/
.btn{
	padding:0 80px 0 30px;
	height:44px;
	line-height:44px;
	border-radius:4px;
	color: $white;
	font-weight:600;
	font-size:12px;
	text-align:left;
	position:relative;
	overflow:hidden;
	display:inline-block;
	box-shadow: 0px 0px 0px 5px rgba($white,0.1);
}
.btn.no-shdow-btn {
	box-shadow:none;
}
.btn.whitebtn i {
	border-color:$gray;
}
.btn:after {
	content:'';
	position:absolute;
	width: 50px;
	height: 100%;
	background: rgba($white,0.2);
	z-index: 0;
	right: 0;
	top: 0;
	transition: all 200ms linear;
}
 
.btn:hover:after {
	width: 120%;
	background: rgba($white,0.1);
 
}
.btn i {
	position:absolute;
	right:0;
	width:50px;
	height:44px;
	line-height:44px;
	border-left:1px solid  rgba($white,0.21);
	text-align:center;
	top:0;
}
.btn.float-btn {
	float:left;
	margin:10px 0 0;
}
.btn.flat-btn {
	color:$white;
}
.down-btn {
	float:left;
	padding:15px 20px;
	margin-right:15px;
	margin-top:15px;
	color:$white;
	box-shadow: 0px 0px 0px 4px rgba($white,0.2);
	font-size:12px;
	border-radius:4px;
}
.down-btn i {
	padding-right:10px;
	font-size:16px;
}
.list-author-widget-text .btn {
	display:inline-table;
	margin-top:16px;
}
.list-single-btn {
	display:inline-block;
	padding:15px 35px;
	background:$white;
	margin-top:90px;
	border-radius:30px;
	position:relative;
	border:1px solid $gray;
	font-size:14px;
}
.list-single-btn i{
	padding-left:10px;
}
.load-more-button {
	display:inline-block;
	color:$white;
	padding:15px 70px;
	margin-top:20px;
	border-radius:6px;
	font-weight:700;
	background: $blue;
	box-shadow: 0px 0px 0px 7px rgba(204,204,204,0.4);
}
.load-more-button i {
	float:right;
	margin-left:18px;
	top:2px;
	position:relative;
	animation-name: spin;
	animation-duration: 900ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
/*-------------accordion---------------------------------------*/
.accordion {
	float: left;
	width: 100%;
	position: relative;
}
.accordion a.toggle {
	border: 1px solid $gray;
	padding: 18px 50px 18px 30px;
	float: left;
	width: 100%;
	position: relative;
	background: $white;
	border-radius: 4px;
	box-sizing: border-box;
	text-align: left;
	font-size: 13px;
	font-weight: 700;
	color:$gray-5;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
	margin-bottom: 15px;
}
.accordion a.toggle span {
	position: absolute;
	right: -1px;
	top: -1px;
	bottom: -1px;
	width: 60px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.accordion a.toggle span:before,
.accordion a.toggle span:after {
	content: '';
	position: absolute;
	background: $white;
	top: 50%;
	left: 50%;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
.accordion a.toggle span:before {
	width: 10px;
	height: 2px;
	margin-top: -1px;
	margin-left: -5px;
}
.accordion a.toggle span:after {
	width: 2px;
	height: 10px;
	margin-top: -5px;
	margin-left: -1px;
}
.accordion a.toggle.act-accordion {
	color: $white;
	border-color: transparent;
}
.accordion a.toggle.act-accordion span:after {
	height: 0;
}
.accordion-inner {
	display: none;
	float: left;
	width: 100%;
	position: relative;
	padding: 20px 30px 15px;
	background: $white;
	margin: 0 0 15px;
	text-align: left;
	border-radius:4px;
	border: 1px solid $gray;
}
.accordion-inner.visible {
	display: block;
}
/* ------Header------------------------------------------------------------ */
.main-header {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	z-index:100;
	border-bottom:1px solid  rgba($black,0.21);
	-webkit-transform: translate3d(0,0,0);
	/*height:231px;*/
	background:$blue;
}
.fs-header {
	padding:0px 50px;
}
.header-top {
	/*	height:180px; */
	border-bottom:1px solid  rgba($black,0.21);
	padding:0 0 6px 0;
	z-index:20;
}
.header-inner {
	height:50px;
	padding:0 0 6px 0;
	z-index:19;
}
.logo-holder {
	float:left;
	position:relative;
	top:12px;
	height:35px;
	text-align: left;
}
.logo-holder img {
	height:100%;
	width:auto;
}
.add-hotel {
	float:right;
	padding: 0 32px;
	position:relative;
	color:$white;
	height:50px;
	line-height:50px;
	font-weight:600;
	transition: all .2s ease-in-out;
	font-size:12px;
	background:$secondary;
	cursor: pointer;
}

.show-reg-form {
	float:right;
	color:$white;
	cursor:pointer;
	margin-right:40px;
	position:relative;
	top:0;
	height:60px;
	line-height:60px;
	font-size:13px;
	font-weight:400;
}
.show-reg-form:before {
	content:'';
	position:absolute;
	left:-20px;
	width:1px;
	top:0;
	bottom:0;
	background:rgba($black,0.21);
}
.show-reg-form i  {
	padding-right:10px;
}
.show-search-button {
	color:$white;
	float:right;
	position:relative;
	width:172px;
	top:0;
	height:50px;
	line-height:50px;
	font-size:13px;
	cursor:pointer;
	font-weight:500;
	border-right:1px solid rgba($black,0.21);
	box-sizing:border-box;
}
.wishlist-link {
	color:$white;
	float:right;
	position:relative;
	width:60px;
	top:0;
	height:50px;
	line-height:50px;
	cursor:pointer;
	font-size:16px;
	color:$white;
	border-left:1px solid rgba($black,0.21);
	box-sizing:border-box;
}
.wishlist-link:hover i {
	font-weight:800;
}
span.wl_counter{
	position:absolute;
	bottom: 16px;
	right: 5px;
	width:15px;
	height:15px;
	line-height:15px;
	background: rgba($black,0.11);
	border-radius:100%;
	z-index:2;
	font-size:10px;
	color:$white;
}
.wishlist-wrap {
	position:absolute;
	top:50px;
	right:0;
	display:none;
	width:400px;
	overflow:auto;
	padding:25px 30px;
	background:$white;
	z-index:-1;
	border-radius:0 0 6px 6px;
	animation: fadeIn 300ms;
	overflow: hidden;
	box-shadow: 0px 1px 15px 1px rgba(113, 106, 202, 0.1);
}
@keyframes fadeIn {
	0% { display: none; right: -20px; }
	100% { display: block; right: 0; }
}

.wishlist-visible {
	display: block;
}
.wishlist-hidden {
	display: none;
	right: -20px;
}
.wishlist-wrap .box-widget-content  {
	padding:0;
}
.show-search-button i{
	padding-left:19px;
	position:relative;
	top:1px;
}
.show-search-button i.vis-head-search-close:before , .wishlist-link.scwllink i:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f00d";
}
.show-search-button:before {
	content:'';
	position:absolute;
	left:0;
	top:0;
	bottom:0;
	width:1px;
	background:rgba($black,0.21);
}
.lang-wrap  , .currency-wrap{
	float:right;
	position:relative;
	top:10px;
	right: 14px;
}
.currency-wrap {
	margin-right:0px;
	top:12px;
}
.lang-tooltip , .currency-tooltip {
	position:absolute;
	top:0;
	right:0;
	width:100px;
	z-index:105;
	background:$white;
	padding:40px 0 12px;
	border-radius:4px;
	border:1px solid $gray;
	border-top:none;
	visibility:hidden;
	opacity:0;
	box-shadow: 0px 0px 0px 7px rgba($white,0.1);
}
.show-lang  , .show-currency-tooltip{
	color:$white;
	cursor:pointer;
	width:100px;
	float:right;
	padding:10px;
	position:relative;
	z-index:106;
	font-size:12px;
	border:1px solid transparent;
}
.show-currency-tooltip span {
	position:relative;
	float:left;
}
.show-currency-tooltip i {
	padding-right:8px;
	float:left;
	position:relative;
	top:1px;
}
.show-lang i , .show-currency-tooltip span i {
	position:absolute;
	right:10px;
	top:50%;
	line-height:10px;
	height:10px;
	margin-top:-5px;
}
.show-currency-tooltip span i {
	right:-30px;
}
.show-lang img , .lang-tooltip li img {
	position:relative;
	border-radius:0px;
}
.show-lang img {
	margin-right:4px;
}
.show-lang span {
	padding:0 10px 0 6px;
	position:relative;
	z-index:2;
}
.show-lang .fa-angle-down {
	position:relative;
	top:2px;
}
.lang-wrap:hover .lang-tooltip  , .currency-wrap:hover .currency-tooltip{
	visibility:visible;
	opacity:1;
}
.lang-wrap:hover .show-lang span  , .currency-wrap:hover .show-currency-tooltip span {
	color:$gray-5;
}
.lang-tooltip li  , .currency-tooltip li{
	float:left;
	width:100%;
	position:relative;
	text-align:left;
	color:$white;
	border-radius:4px;
	padding:6px 14px;
}
.lang-tooltip li:hover , .currency-tooltip li:hover {
	background:$blue;
}
.currency-tooltip li {
	margin-bottom:4px;
	padding-left:10px;
}
.currency-tooltip li:last-child {
	margin-bottom:0;
}
.currency-tooltip li i {
	margin-right:8px;
}
.lang-tooltip li img {
	margin-right:10px;
}
.lang-tooltip li:hover a , .currency-tooltip li:hover a {
	color:$white;
}
.lang-tooltip li a , .currency-tooltip li a{
	color: $blue-light;
	font-weight:500;
	font-size:10px;
	text-transform:uppercase;
}
.header-search {
	position:absolute;
	top:110px;
	left:0;
	width:100%;
	padding:20px 30px 30px;
	background:$white;
	z-index:18;
	border-bottom:1px solid $gray;
	opacity:0;
	margin-top:30px;
	visibility:hidden;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
.vis-head-search {
	opacity:1;
	visibility:visible;
	margin-top:0;
}
.header-search-input-item  label , .range-slider-title , .col-list-search-input-item label , .leave-rating-holder .leave-rating-title{
	float:left;
	width:100%;
	text-align:left;
	margin-bottom:10px;
	color: $gray-dark;
	font-size:10px;
	font-weight: 600;
	text-transform:uppercase;
}
.header-search-input-item input[type="text"] , .col-list-search-input-item input[type="text"] {
	float:left;
	width:100%;
	border:1px solid $gray;
	padding:12px 32px 12px 33px;
	border-radius:6px;
	background: $gray-light;
	height:42px;
	line-height:42px;
}
.header-search-input-item input[type="text"]:focus , .col-list-search-input-item input[type="text"]:focus {
	background:$white;
}
.header-search-input-item.in-loc-dec:before , .col-list-search-input-item.in-loc-dec:before , .header-search-input-item.date-parent:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f107";
	position:absolute;
	color:$white;
	bottom:10px;
	font-size:15px;
	right:14px;
	font-weight:500;
	z-index:120;
	transition: all .2s ease-in-out;
}
.not-vis-arrow {
	z-index:520;
}
.not-vis-arrow:before {
	display:none;
}
.header-search-input-item input[type="number"]  , .col-list-search-input-item input[type="number"]{
	border-radius:6px;
	background: $white;
}
.header-search-input-item .quantity-item  , .col-list-search-input-item  .quantity-item {
	float:left;
	width:33.3%;
}
.header-search-input-item-icon {
	position:absolute;
	bottom:14px;
	left:13px;
	z-index:100;
	font-weight:600;
	font-size:14px;
}
.header-search-button {
	float:left;
	width:100%;
	height:42px;
	line-height:42px;
	padding:0 20px;
	color:$white;
	border:none;
	border-radius:4px;
	position:relative;
	top:20px;
	left:0;
	outline:none;
	cursor:pointer;
}
.header-search-button i {
	padding-left:10px;
}
.header-search-input-item.location a , .col-list-search-input-item.location a  {
	position: absolute;
	bottom: 12px;
	right: 16px;
	z-index: 500;
}
.close-header-search {
	position:absolute;
	width:100px;
	height:26px;
	right:50%;
	margin-right:-50px;
	line-height:26px;
	bottom:-27px;
	color:$white;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	cursor:pointer;
}
.search-opt-wrap-container {
	margin-left:-15px;
	margin-right:-15px;
	overflow:hidden;
}
.search-input-item {
	float:left;
	width:50%;
	padding:0 16px;
}
.search-input-item.midd-input {
	width:30%;
	padding:0 15px;
}
.search-input-item.small-input {
	width:20%;
	padding:0 18px 0 15px;
}
.home-btn {
	float:left;
	width:50px;
	height: 50px;
	margin-right:15px;
	line-height:50px;
	color:$white;
	border-right: 1px solid rgba($black,0.21);
	border-left: 1px solid rgba($black,0.21);
	box-sizing: border-box;
}
.home-btn a {
	color:$white;
}
/* ------Navigation------------------------------------------------------------ */
.nav-holder {
	float:left;
	position:relative;
	opacity:1;
	left:0;
	visibility:visible;
	z-index:20;
}
.nav-holder nav {
	position:relative;
	float: right;
}
.nav-holder nav li{
	float:left;
	position:relative;
	margin-right:6px;
	margin-top: 3px;
	height:50px;
}
.nav-holder nav li ul {
	margin: 30px 0 0 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	min-width:150px;
	top: 50px;
	left: 0;
	z-index: 1;
	padding:10px 0;
	background:$white;
	border-radius:6px;
	border:1px solid $gray;
	transition: all .2s ease-in-out;
}
.nav-holder nav li:hover > ul {
	opacity: 1;
	visibility: visible;
	margin: 0;
}
.nav-holder nav li ul li ul {
	top: -10px  ;
	left: 100%;
	margin-left:25px;
	margin-top:0;
	max-width:150px;
}
.nav-holder nav li ul li:hover  > ul  {
	opacity: 1;
	visibility: visible;
	margin-right:0px;
}
.nav-holder nav li ul li {
	width:100%;
	float:left;
	height:auto;
	position:relative;
}
.nav-holder nav li a {
	float: left;
	padding: 10px;
	font-size: 13px;
	font-weight: 700;
	text-transform: none;
	line-height: 25px;
	letter-spacing: 0px;
	color: $white;
	-webkit-transition: all 100ms linear;
	transition: all 100ms linear;
}
.dark-header .nav-holder nav li a  {
	color:$white;
}
.nav-holder nav li a i {
	padding-left:6px;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.nav-holder nav li a:hover i {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}
.nav-holder nav li ul a {
	color:$blue-light;
	float:left;
	width:100%;
	font-weight:700;
	text-align:left;
	padding:5px 15px;
	font-size: 12px;
}
.dark-header .nav-holder nav li ul a{
	color:$black;
}
nav li ul a:before , nav li ul a:after {
	display:none;
}
.nav-button-wrap {
	float:left;
	height:34px;
	width:34px;
	cursor:pointer;
	position:relative;
	border-radius:8px;
	top:8px;
	display:none;
}
.nav-button  {
	position:absolute;
	top:50%;
	left:50%;
	width:22px;
	height:20px;
	margin-left:-11px;
	margin-top:-6px;
}
.nav-button span{
	float:left;
	width:100%;
	height:2px;
	background:$white;
	margin-bottom:4px;
	border-radius:4px;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
}
.header-user-menu {
	float:right;
	position:relative;
	top:8px;
	margin-right:50px;
}
.header-user-name {
	position:relative;
	float:left;
	cursor:pointer;
	color:$white;
	transition: 0.2s;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding-top:10px;
	font-weight:700;
	font-size:12px;
}
.header-user-name:before,
.qty-dropdown-header:before ,
.main-search-input-item_small:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f0d7";
	position:absolute;
	color:$white;
	bottom:1px;
	font-size:13px;
	right:-26px;
	font-weight:800;
	transition: all .2s ease-in-out;
	z-index:20;
}
.main-search-input-item_small:before , .qty-dropdown-header:before {
	bottom:16px;
	right:20px;
}
.qty-dropdown-header:before {
	bottom:-2px;
}
.header-user-name span {
	position:absolute;
	width:32px;
	height:32px;
	border-radius:100%;
	overflow:hidden;
	top:0;
	left:-50px;
}
.header-user-name span img {
	width:100%;
	height:100%;
}
.header-user-menu ul {
	margin: 10px 0 0 0;
	opacity: 0;
	visibility: hidden;
	position: absolute;
	min-width: 150px;
	top: 60px;
	left: -50px;
	z-index: 1;
	padding: 10px 0;
	background: $white;
	border-radius: 6px;
	border: 1px solid $gray;
	transition: all .2s ease-in-out;
}
.header-user-name.hu-menu-visdec:before {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}
.header-user-menu ul.hu-menu-vis {
	opacity: 1;
	visibility: visible;
	top: 40px;
}
.header-user-menu ul li {
	float:left;
	width:100%;
	padding:4px 0;
}
.header-user-menu ul li a {
	color: $blue-light;
	float: left;
	width: 100%;
	font-weight: 600;
	font-size:12px;
	text-align: left;
	padding: 6px 15px;
}
/*------footer -------------------------------------------------*/
.main-footer  , .footer-inner , .sub-footer , .top-footer{
	float:left;
	width:100%;
	position:relative;
}
.main-footer   {
	background:$blue;
	z-index:3;
	overflow:hidden;	
}
.footer-inner {
	padding:70px 0;
	z-index:2;
	border-top:2px solid  rgba($black,0.21);
}
.footer-bg {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
	background-image:url(../../images/bg/4.jpg);
	background-size: cover;
	background-attachment: scroll  ;
	background-position: center  ;
	background-repeat:repeat;
	opacity:0.04;
	background-origin: content-box;
}
.sub-footer {
	background: $blue-dark;
	padding:34px;
	z-index:3;
}
.copyright {
	float:left;
	color:$white;
	font-size:12px;
}
.subfooter-nav {
	float:right;
	margin-right:50px;
}
.subfooter-nav  li {
	float:left;
	margin-right:20px;
}
.subfooter-nav  li a {
	color:rgba($white,0.61);
	font-weight:400;
	font-size:12px;
}
.subfooter-lang {
	float: right;
	padding-left:70px;
	position:relative;
	height:25px;
}
.subfooter-show-lang {
	float:left;
	color:$white;
	position:relative;
	cursor:pointer;
}
.subfooter-show-lang i {
	margin-left:10px;
}
.subfooter-lang:before  {
	content:'';
	position:absolute;
	top:0;
	left:0;
	background:url(../../images/map.png) no-repeat center;
	width:52px;
	height:25px;
	opacity:0.8;
}
.subfooter-lang-tooltip {
	position:absolute;
	bottom:0;
	margin-bottom:30px;
	padding:15px 0;
	background: $blue;
	right:0;
	width:80%;
	border-radius:4px;
	border: 1px solid rgba($black,0.21);
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
	visibility:hidden;
	opacity:0;
}
.subfooter-lang:hover .subfooter-lang-tooltip {
	visibility: visible;
	opacity:1;
}
.subfooter-lang-tooltip li {
	float:left;
	width:100%;
	margin-bottom:6px;
}
.subfooter-lang-tooltip li a {
	text-align:right;
	float:left;
	width:100%;
	padding:0 10px;
	font-weight:500;
}
.footer-carousel-title{
	float:left;
	color:$white;
	text-align:left;
	text-transform:uppercase;
	font-weight:600;
	position:relative;
	top:40px;
	font-size:14px;
}
.footer-fw-widget {
	padding-bottom:30px;
	margin-bottom:70px;
	border-bottom:1px solid rgba($white,0.21);
}
.footer-carousel-item img {
	width:100%;
	height:auto;
}
.footer-carousel-item a {
	opacity:0.6;
	float:left;
}
.footer-widget h3 {
	font-size:14px;
	float:left;
	text-transform:uppercase;
	text-align:left;
	width:100%;
	margin-bottom:30px;
	color:$white;
	font-weight:600;
	border-bottom:1px solid rgba($white,0.11);
	padding-bottom:25px;
}
.footer-social {
	float:left;
	margin-top:20px;
}
.footer-social  span {
	float: left;
	color: $white;
	text-align: left;
	text-transform: uppercase;
	font-weight: 600;
	padding-right:30px;
	position:relative;
	top:8px;
	font-size:14px;
}
.footer-social ul {
	float:left;
}
.footer-social li {
	float:left;
	margin-right:14px;
}
.footer-social li a{
	float:left;
	border-left:none;
	font-size:13px;
	width:32px;
	height:32px;
	line-height:32px;
	background:rgba($white,0.05);
	border:1px solid rgba($white,0.11);
	border-radius:4px;
}
.footer-menu {
	margin-top:20px;
}
.footer-menu  li {
	float:left;
	text-align:left;
	padding-right:16px;
	position:relative;
}
.footer-menu  li:before {
	content:'';
	position:absolute;
	top:50%;
	margin-top:-1px;
	width:4px;
	height:4px;
	right:6px;
	border-radius:100%;
}
.footer-menu  li:last-child:before {
	display:none;
}
.footer-menu  li a {
	color:$gray;
	font-size:10px;
	font-weight:500;
	letter-spacing:2px;
	text-transform:uppercase;
	font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}
.footer-menu  li a:hover {
	color:rgba($white,0.41);
}
#footer-twiit img , p.interact , #footer-twiit div.user {
	display:none;
}
#footer-twiit {
	text-align:left;
}
#footer-twiit a {
	color:$white;
}
#footer-twiit p.tweet {
	font-size:11px;
	color:$white;
	float:left;
	padding:20px 22px;
	font-weight:500;
	margin-bottom:10px;
	position:relative;
}
.no-border {
	border:none !important;
}
#footer-twiit p.tweet:before {
	content:'';
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index:-2;
	border-radius:6px;
	opacity:0.14;
}
#footer-twiit p.tweet:after {
	font-family: Font Awesome\ 5 Brands;
	content: "\f099";
	position:absolute;
	bottom:10px;
	right:12px;
	color:$white;
	opacity:0.2;
	font-size:34px;
	z-index:-1;
}
#footer-twiit  .timePosted a {
	color:$gray-2;
	position:relative;
	float:left;
	margin-bottom:30px;
	padding-left:20px;
}
#footer-twiit  .timePosted a:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f017";
	position:absolute;
	top:0;
	left:0;
	color:$gray-2;
	opacity:0.7;
	font-size:14px;
}
#footer-twiit  .timePosted a:after {
	top: -10px;
	left: 22px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
#footer-twiit  .timePosted a:after {
	border-color: transparent;
	border-top-color: $secondary;
	border-width: 7px;
	margin-left: 7px;
	opacity:0.1
}
.twitter-link {
	float:left;
	color:$white;
	background:rgba($white,0.05);
	border:1px solid rgba($white,0.11);
	padding:8px 20px;
	border-radius:2px;
	font-size:12px;
	box-shadow: 0px 0px 0px 4px rgba($white,0.1);
}
.contact-btn {
	float:left;
	color:$white;
	padding:15px 35px;
	font-size:12px;
	margin-top:50px;
	font-weight:600;
	border-radius:4px;
	position:relative;
	top:28px;
	box-shadow: 0px 0px 0px 7px rgba($white,0.1);
}
.contact-btn i {
	padding-left:15px;
	color:rgba($white,0.81);
	font-size:16px;
	position:relative;
	top:1px;
}
.customer-support-widget {
	margin-top:50px;
	padding:25px 30px 15px 45px;
	border-radius:4px;
	background: rgba($black,0.04);
	overflow:hidden;
	border:1px solid rgba($white,0.1);
}
.customer-support-widget:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f4fe";
	position:absolute;
	z-index:-1;
	color:$white;
	font-size:84px;
	opacity:0.07;
	left:14px;
	bottom:8px;
}
.customer-support-widget h4  {
	float:left;
	color:$white;
	font-weight:600;
	font-size:12px;
	text-align:left;
	width:100%;
	margin-bottom:6px;
}
.cs-mumber {
	float:left;
	text-align:left;
	font-size:20px;
	color:$white;
	font-weight:600;
	position:relative;
	top:6px;
	color:$white;
}
.cs-mumber:hover {
	color:rgba($white,0.6);
}
.cs-mumber-button {
	float:right;
	padding:15px 20px;
	color:$white;
	font-size:12px;
	font-weight:600;
	border-radius:4px;
	top:-14px;
	position:relative;
}
.cs-mumber-button i {
	padding-left:10px;
}
.contact-btn:hover{
	background: $blue;
}
.subscribe-wrap {
	padding:90px 0;
	overflow:hidden;
}
.subscribe-header h3 {
	float:left;
	width:100%;
	padding-bottom:15px;
	color:$white;
	text-align:left;
	font-weight:600;
	font-size:22px;
}
.subscribe-header p {
	color:rgba($white,0.71);
	text-align:left;
}
.footer-widget #subscribe .enteremail {
	background:$white;
	height:50px;
	line-height:50px;
	border-radius:4px;
	padding:0 120px 0 15px;
	box-sizing:border-box;
	border:1px solid rgba($white,0.21);
	z-index:1;
}
.sp-bg {
	position:absolute;
	bottom:-240px;
	left:-70px;
	width:350px;
	height:350px;
	border-radius:100%;
	background:rgba($white,0.11);
	box-shadow: 0px 0px 30px 0px rgba(50, 50, 50, 0.05);
}
.sp-bg:before {
	content:'';
	position:absolute;
	top:-50px;
	left:-50px;
	bottom:-50px;
	right:-50px;
	border-radius:100%;
	background:rgba($white,0.051);
}
#subscribe {
	float:left;
	width:100%;
	position:relative;
	top:30px;
}
#subscribe:before {
	content:'';
	position:absolute;
	top:-9px;
	left:-9px;
	right:-9px;
	bottom:-9px;
	background:rgba($white,0.18);
	z-index:-1;
	border-radius:4px;
}
.footer-widget #subscribe-button {
	position:absolute;
	right:0;
	width:120px;
	height:50px;
	line-height:50px;
	top:0;
	cursor:pointer;
	border:none;
	border-top-right-radius:4px;
	border-bottom-right-radius:4px;
	z-index:2;
	background: $blue;
	color:$white;
}
.footer-widget #subscribe-button:hover i {
	color:rgba($white,1.0);
}
.subscribe-message {
	color:$white;
	float:left;
	margin-top:10px;
	text-align:left;
	font-weight:500;
	line-height:19px;
}
.subscribe-message i {
	padding-right:6px;
}
.subscribe-message.valid i{
	color:$green-light;
}
.subscribe-message.error i{
	color:$red;
}
.footer-widget #subscribe-button i {
	padding-right:6px;
}
.sub-footer .about-widget img {
	float:left;
	width:120px;
	height:auto;
}
.footer-widget .footer-contacts-widget p {
	color:$white;
	text-align: left;
}
.footer-widget .about-widget p {
	border-bottom:1px solid rgba($white,0.21);
}
.footer-widget .about-widget .btn {
	float:left;
	margin-top:20px;
	padding:13px 20px;
	border-radius:20px;
	color:$white;
	font-weight:600;
}
.footer-contacts {
	padding-bottom: 5px;
	border-bottom:1px  dashed rgba($white,0.21);
}
.divider {
	margin: 5px 0 15px 0;
	border-top:1px  dashed rgba($white,0.21);
}
.footer-contacts li {
	float:left;
	width:100%;
	text-align:left;
	padding:10px 0;
	font-size:13px;
	font-weight:500;
}
.footer-contacts li:first-child {
	padding-top: 2px;
}
.footer-contacts li  a , .footer-contacts li span {
	color:rgba($white,0.51);
}
.footer-contacts li  a {
	color:rgba($white,0.81);
	padding-left:4px;
}
.footer-contacts li i {
	padding-right:4px;
}
.footer-widget .widget-posts li , .box-widget-content .widget-posts li {
	padding: 13px 0;
	margin-bottom:10px;
	width:100%;
}
.box-widget-content .widget-posts li {
	border-bottom:1px solid $gray;
	margin-bottom:13px;
	padding: 0 0 13px;
}
.box-widget-content .widget-posts li:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}
.footer-widget .widget-posts-img  , .box-widget-content .widget-posts-img , .cart-details_header  .widget-posts-img  {
	float:left;
	width:30%;
}
.footer-widget .widget-posts-img img  , .box-widget-content .widget-posts-img  img , .cart-details_header  .widget-posts-img img{
	border-radius:4px;
}
.footer-widget .widget-comments-img  img{
	border-radius:100%;
}
.footer-widget .widget-posts-descr  , .box-widget-content  .widget-posts-descr , .cart-details_header .widget-posts-descr{
	float:left;
	width:70%;
	padding-left:20px;
	text-align:left;
	position:relative;
}
.box-widget-content  .widget-posts-descr a  , .cart-details_header .widget-posts-descr a{
	color:$gray-5;
}
.box-widget-content  .widget-posts-descr  .geodir-category-location , .cart-details_header .widget-posts-descr .geodir-category-location{
	margin-top:12px;
}
.box-widget-content  .widget-posts-descr  .geodir-category-location a , .cart-details_header .widget-posts-descr .geodir-category-location a{
	font-size:10px;
	color: $gray-dark;
}
.box-widget-content  .widget-posts-descr .rooms-price {
	font-size:12px;
	text-transform:uppercase;
	top:0px;
}
.box-widget-content  .widget-posts-descr .rooms-price strong {
	font-size:9px;
}
.box-widget-content  .widget-posts-descr .listing-rating , .cart-details_header   .widget-posts-descr .listing-rating{
	margin-top:6px;
}
.box-widget-content  .widget-posts-descr .listing-rating i , .cart-details_header   .widget-posts-descr .listing-rating i{
	font-size:10px;
	margin-right:2px;
}
.box-widget-content .widget-posts-link {
	float:left;
	position:relative;
	cursor:pointer;
	font-weight: 600;
	font-size: 12px;
	margin-top:20px;
	color: $gray-dark;
}
.widget-posts-link:hover {
	color:$gray-5;
}
.box-widget-content .widget-posts-link:before {
	display:none;
 
}
.box-widget-content .widget-posts-link i {
	padding-left:10px;
	font-size:14px;
	position:relative;
	top:2px;
}
.footer-widget .widget-posts-descr:before {
	content:'';
	position:absolute;
	bottom:-12px;
	left:20px;
	right:0;
	height:1px;
	border-bottom:1px  dashed rgba($white,0.21);
}
.footer-widget .widget-posts-descr a{
	font-size:12px;
	color: rgba($white,0.71);
	font-weight:600;
	padding-bottom:20px;
}
.footer-widget .widget-posts-descr a:hover {
	color:$white;
}
.footer-widget .widget-posts-date  {
	float:left;
	width:100%;
	margin-top:10px;
	color:$gray-4;
	font-weight:600;
	font-size:12px;
}
.footer-widget .widget-posts-date i {
	color: rgba($white,0.41) !important;
	padding-right:10px;
}
.about-wrap {
	margin-bottom:30px;
}
.about-wrap .list-single-main-item-title  h3 {
	font-size:24px;
}
.about-wrap p , .about-wrap h4{
	text-align:left;
}
.about-wrap h4 {
	font-size:16px;
	padding:10px 0 0;
	font-weight:600;
	color:$gray-10;
}
.about-wrap .section-separator {
	margin-bottom:5px;
}
.fc-cont {
	position:absolute;
	top:50%;
	z-index:100;
	width:20px;
	height:20px;
	line-height:20px;
	margin-top:-10px;
	color:$white;
	font-size:16px;
	cursor:pointer;
}
.fc-cont.fc-cont-prev {
	left:0;
}
.fc-cont.fc-cont-next {
	right:0;
}
.fc-cont.lc-prev {
	left:-20px;
	font-size:20px;
}
.fc-cont.lc-next {
	right:-20px;
	font-size:20px;
}
/*------ section-------------------------------------------------*/
section {
	float:left;
	width:100%;
	position:relative;
	padding:50px 0;
	background:$white;

}
section.middle-padding {
	padding:50px 0;
}
.small-padding {
	padding:30px 0;
}
section.no-top-pading {
	padding-top:0;
}
section.no-pading {
	padding-bottom:0;
}
.section-title {
	margin-bottom:40px;
}
.section-title h2 {
	float:left;
	width:100%;
	margin-bottom:5px;
	font-size:24px;
	font-weight:600;
	color: $blue-light;
	font-family: 'Montserrat', sans-serif;
}
.section-title h3 {
	float:left;
	width:100%;
	color: $blue;
	font-size:18px;
	font-weight:600;
}
.section-title h4 {
	float:left;
	width:100%;
	color: $blue-light;
	font-size:12px;
	font-weight:600;
}
.section-title-separator {
	position:relative;
	max-width:450px;
	margin:0 auto 10px;
}
.section-title-separator span {
	position:relative;
	display:inline-table;
	z-index:2;
}
.section-title-separator span i {
	padding:0 2px;
	color:$gray-11
}
.section-title-separator span i:nth-child(2) {
	font-size:16px;
}
section.parallax-section {
	padding:110px 0;
	overflow:hidden;
}
section.video-section {
	padding:150px 0;
	overflow:hidden;
}
section.single-par {
	padding:140px 0;
	overflow:hidden;
}
section.big-padding {
	padding:350px 0;
	overflow:hidden;
}
section.parallax-section.small-par {
	padding:90px 0;
}
section.parallax-section  .section-title-separator span , .ajax-modal-title  .section-title-separator span{
	padding:0;
	background:none;
}
.section-title-separator span i  , .ajax-modal-title  .section-title-separator span i{
	padding:0 2px;
	color:$gray-11
}
.section-title-separator span i:nth-child(2) {
	font-size:16px;
}
.ajax-modal-title  .section-title-separator span i {
	font-size:10px;
}
.ajax-modal-title .section-title-separator span i:nth-child(2) {
	font-size:13px;
}
section.parallax-section  .section-title-separator:before , .ajax-modal-title  .section-title-separator:before {
	display:none;
}
section.parallax-section .overlay {
	opacity:0.4;
}
.hero-section-wrap {
	z-index:30;
}
section.parallax-section .bg  , .list-single-hero .bg , .hero-parallax .bg{
	height:120%;
	top:0;
}
.section-separator {
	float:left;
	width:100%;
	position:relative;
	margin:10px 0 15px;
}
.section-separator:before{
	content:'';
	position:absolute;
	left:50%;
	top:0;
	height:4px;
	margin-left:-25px;
	width:50px;
	border-radius:3px;
}
.breadcrumbs.inline-breadcrumbs {
	margin-bottom:20px;
	padding:19px 20px;
	background:$white;
	border-radius:4px;
}
.breadcrumbs.inline-breadcrumbs a , .breadcrumbs.inline-breadcrumbs span , .dasboard-breadcrumbs a , .dasboard-breadcrumbs span {
	float:left;
	padding:0 16px;
	position:relative;
	color: $gray;
	font-size:12px;
}
.breadcrumbs.inline-breadcrumbs span {
	color:$gray-5;
}
.breadcrumbs.inline-breadcrumbs a:first-child , .dasboard-breadcrumbs a:first-child  {
	padding-left:0;
}
.dasboard-breadcrumbs a  , .dasboard-breadcrumbs span {
	padding:0 0 0 32px;
	color:$white;
}
.dasboard-breadcrumbs span  , section.color-bg .section-title p{
	color:rgba($white,0.71);
}
.section-title .breadcrumbs , .flat-title-wrap .breadcrumbs{
	margin-top:20px;
}
.section-title .breadcrumbs a , .section-title .breadcrumbs  span , .breadcrumbs-hero-buttom  .breadcrumbs a , .breadcrumbs-hero-buttom  .breadcrumbs span , .flat-title-wrap .breadcrumbs span , .flat-title-wrap .breadcrumbs a{
	color:rgba($white,0.71);
	display:inline-block;
	padding:0 16px;
	position:relative;
	font-weight:500;
}
.section-title .breadcrumbs  span , .section-title .breadcrumbs a:hover , section.parallax-section .section-title h2  , .breadcrumbs-hero-buttom  .breadcrumbs a , .flat-title-wrap .breadcrumbs a {
	color:$white;
}
section.parallax-section .section-title.big-title h2 {
	font-size:38px;
}
.section-title .breadcrumbs a:before , .breadcrumbs-hero-buttom  .breadcrumbs a:before , .breadcrumbs.inline-breadcrumbs a:before , .dasboard-breadcrumbs a:before , .flat-title-wrap .breadcrumbs a:before , .breadcrumbs-fs .breadcrumbs a:before{
	font-family: Font Awesome\ 5 Pro;
	content: "\f105";
	font-size: 12px;
	position:absolute;
	top:50%;
	right:-4px;
	margin-top:-7px;
	width:10px;
	height:2px;
}
.dasboard-breadcrumbs a:before {
	right:-20px;
	content: "\f0da";
}
.breadcrumbs-fs {
	background:$white;
	border-bottom:1px solid $gray;
	padding:25px 0;
	overflow:hidden;
}
.breadcrumbs-fs .breadcrumbs a , .breadcrumbs-fs .breadcrumbs span {
	float:left;
	margin-right:15px;
	padding-right:15px;
	color: $gray-dark;
	font-size:12px;
}
.breadcrumbs-hero-buttom  .breadcrumbs a:before {
	right:10px;
}
.breadcrumbs-hero-buttom {
	margin-top:25px;
	padding-top:20px;
	border-top:1px solid rgba($white,0.21);
}
.list-single-hero-price {
	float:right;
	font-size:11px;
}
section.color-bg .section-title h2 , .list-single-hero-price  , .header-sec-link  a , .flat-title-wrap h2{
	color:$white;
}
.list-single-hero-price span{
	color: $green-light;
	font-size:24px;
	font-weight:600;
	padding-left:20px;
	position:relative;
	top:2px;
}
.breadcrumbs-hero-buttom  .breadcrumbs {
	float:left;
	position:relative;
	top:10px;
}
.breadcrumbs-hero-buttom  .breadcrumbs a , .breadcrumbs-hero-buttom  .breadcrumbs span {
	float:left;
	padding:0 32px 0 0;
}
section.color-bg .section-title .breadcrumbs {
	margin-top:0;
	margin-bottom:10px;
}
section.color-bg .section-title .breadcrumbs a:before {
	background:$white !important;
}
.header-sec-link {
	position:absolute;
	bottom:0;
	z-index:10;
	left:0;
	width:100%;
}
.header-sec-link  a {
	display:inline-block;
	font-size:19px;
	padding:8px 60px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}
.flat-title-wrap {
	padding:20px 0;
}
.flat-title-wrap h2 {
	font-size:30px;
	font-weight:600;
	position:relative;
	font-family: 'Montserrat', sans-serif;
}
.section-decor{
	position:absolute;
	left:0;
	bottom:0;
	width:100%;
	height:150px;
	z-index:-1;
	background:url(../../images/city7.png) repeat;
	opacity:0.7;
}
section.parallax-section .section-title h4 , .flat-title-wrap h4  {
	color:rgba($white,0.71);
	font-size:12px;
	font-weight:400;
}
.no-mar-bottom {
	margin-bottom:0 !important;
}
.hero-parallax {
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	overflow:hidden;
}
section.hero-section   {
	padding:150px 0;
	z-index:100;
}
.slider-container .slider-item{
	padding:150px 80px;
} 
.home-intro h2 , .home-intro-card h3{
	font-size:44px;
	font-weight:700;
	color:$white;
	font-family: 'Montserrat', sans-serif;
}
.home-intro-card {
	margin-top:50px;
}
.home-intro-card .btn{
	margin-top:20px;
}
.home-intro-card h3 {
	text-align:left;
	font-size:34px;
}
.home-intro-card h5 {
	text-align:left;
	color:rgba($white,0.7);
	font-size:12px;
	padding-top:10px;
}
.home-intro h3  {
	color:$white;
	padding-top:12px;
}
.home-intro-card-counter {
	float:left;
	margin-bottom:10px;
	font-size: 11px;
	border-radius: 4px;
	background: $blue;
	color: $white;
	padding: 9px 12px;
	position:relative;
	top:-4px;
	box-shadow: 0px 0px 0px 5px rgba($white,0.2);
}
.home-intro-card-counter.home-intro-card-counter_price {
	top:0;
	margin-top:10px;
}
.home-intro-card .listing-rating i{
	margin-right:4px;
}
.home-intro-subtitle {
	padding-top:60px;
	color: $white;
	font-family: 'Montserrat', sans-serif;
	font-weight:300;
	font-size:12px;
}
.main-search-input {
	height: 50px;
	padding:0 150px 0 0;
	border-radius:4px;
	box-shadow: 0px 0px 0px 10px rgba($white,0.3);
}
.main-search-input-item {
	height: 100%;
	box-sizing:border-box;
	border-left:1px solid $gray;
	position:relative;
	background:$white;
	width: 25%;

	&:last-child {
		background-color: none;
	}
}
.main-search-input-item.location {
	border:none;
}
.main-search-input-item.location  input , .main-search-input-item.location{
	border-radius:6px 0 0 6px;
}
.inpt_dec {
	position:absolute;
	left:15px;
	top:18px;
	z-index:20;
}
.main-search-button {
	position:absolute;
	right:0px;
	height:50px;
	width:151px;
	color:$white;
	top:0;
	border:none;
	border-top-right-radius:6px;
	border-bottom-right-radius:6px;
	cursor:pointer;
}
.main-search-button i {
	padding-left:10px;
}
.main-search-input-wrap {
	max-width:940px;
	margin:40px auto;
	position:relative;
}
.main-search-input:before {
	content:'';
	position:absolute;
	bottom:-40px;
	width:50px;
	height:1px;
	background:rgba($white,0.41);
	left:50%;
	margin-left:-25px;
}
.main-search-input-item.location a {
	position:absolute;
	top:18px;
	right:20px;
	z-index:312
}
.main-search-input-item.location a:hover{
	border-radius:100%;
	animation: pulse 1.1s infinite ease-in-out;
}
.qty-dropdown-header {
	color:$gray-5;
	text-align:left;
	padding-left:15px;
	height:50px;
	line-height:50px;
	cursor:pointer;
	position:relative;
	font-family: Nunito, sans-serif;
	font-size: 13px;
	font-weight: 600;
}
.qty-dropdown-header i {
	padding-right:10px;
}
.qty-dropdown-content {
	border:1px solid $gray;
	background:$white;
	padding:15px 20px;
	border-radius: 0 0 6px 6px;
	overflow:hidden;
	display:none;
	box-sizing:border-box;
}
.qty-dropdown-content .quantity-item {
	width:100%;
	float:left;
	margin-bottom:10px;
	padding-bottom:10px;
	border-bottom:1px solid $gray;
}
.qty-dropdown-content .quantity-item:last-child {
	padding-bottom:0;
	margin-bottom:0;
	border:none;
}
.qty-dropdown-content .quantity-item label {
	float:left;
	color: $gray-5;
	font-size: 12px;
	font-weight: 300;
	position:relative;
	top:12px;
}
.qty-dropdown-content .quantity-item label i {
	padding-right:10px;
	font-size:16px;
	width:20px;
}
.qty-dropdown-content .quantity-item .quantity{
	float:right;
}
/*------ process------------------------------------------------*/
.colomn-text-title h3 {
	text-align:left;
	font-size:30px;
	color:$white;
	font-weight:600;
	padding-bottom:10px;
}
.colomn-text-title p  , .colomn-text-list li p{
	text-align:left;
	color:rgba($white,0.71);
}
.colomn-text {
	float:left;
	width:100%;
	position:relative;
	padding:0 40px 0 0;
}
.pad-top-column-text {
	padding:80px;
	margin-bottom:20px;
}
.pad-top-column-text_small {
	padding-top:40px;
}
.colomn-text-list .btn  , .intro-item .btn , .mar-top-btn {
	margin-top:30px;
}
.hidden-section {
	overflow:hidden;
}
.intro-item h2 {
	font-size: 34px;
	color: $white;
	font-weight: 700;
	padding-bottom: 30px;
	position:relative;
}
.intro-item.big-text h2 {
	font-size:44px;
	font-family: 'Montserrat', sans-serif;
}
.intro-item h2:before {
	content:'';
	position:absolute;
	left:50%;
	width:50px;
	height:4px;
	border-radius:2px;
	margin-left:-25px;
	bottom:15px;
}
.intro-item h3 {
	color: $white;
	font-size: 13px;
	font-weight: 500;
}
.intro-item.big-text h3 {
	font-size:18px;
}
.light-carousel .slick-slide-item {
	padding:10px 20px;
	-webkit-transform: translate3d(0,0,0);
}
.hotel-card .geodir-category-img{
	border-radius: 6px;
	box-shadow: 0px 0px 0px 6px rgba($white,0.1);
}
.hotel-card .geodir-category-location {
	float:left;
	position:relative;
	top:18px;
}
.hotel-card  .geodir-category-opt h4 {
	position:relative;
	top:10px;
}
.hotel-card .geodir-category-location a {
	float:left;
	color:$white;
	font-size:10px;
}
.hotel-card .geodir-category-opt .listing-rating {
	top:4px;
} 
/*-------------Team---------------------------------------*/
.team-box {
	float:left;
	width:33.3%;
	position:relative;
	padding:10px 4px;
}
.section-team .team-box {
	padding:10px 8px;
}
.team-photo {
	margin-bottom:20px;
	overflow:hidden;
	border-radius:6px;
}
.section-team .team-photo {
	margin-bottom:0;
	border-bottom-left-radius:0;
	border-bottom-right-radius:0;
}
.team-info {
	float:left;
	width:100%;
	text-align:left;
}
.section-team .team-info {
	border:1px solid $gray;
	padding:25px 30px 70px 30px;
	border-top:none;
	position:relative;
	border-bottom-left-radius:6px;
	border-bottom-right-radius:6px;
	overflow:hidden;	
}
.team-info h3 , .team-info h4 {
	float:left;
	width:100%;
	padding-bottom:10px;
	font-weight:600;
	font-size:16px;
}
.team-info h4 {
	font-size:11px;
	color:$gray-5;
}
.team-social  {
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	padding-left:30px;
}
.team-social ul {
	float:left;
}
.team-social li {
	float:left;
	height:50px;
	line-height:50px;
	margin-right:15px;
}
.team-social li a:hover {
	color:$gray-4;
}
.team-contact_link {
	float:right;
	width:60px;
	height:50px;
	line-height:50px;
	color:$white;
	border-left: 1px solid rgba($black,0.21);
	box-sizing:border-box;
	text-align:center;
}
.team-dec {
	position:absolute;
	top:20px;
	right:30px;
	font-size:26px;
	z-index:-1;
	color: $gray-10;
}
/*------ register form------------------------------------------------*/
.main-register-wrap , .reg-overlay {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1000;
	overflow: auto;
	display: none;
	-webkit-transform: translate3d(0,0,0);
	background:rgba($black,0.81);
}
.reg-overlay {
	z-index:1;
	background:none;
}
.hid-body {
	overflow:hidden;
}
.main-register-holder {
	max-width:420px;
	margin:50px auto 50px;
	position:relative;
	z-index:5;
}
.main-register h3 {
	float:left;
	width:100%;
	text-align:left;
	font-size:20px;
	padding:20px 0;
	margin-bottom:30px;
	font-weight:600;
	color:$gray-5;
	border-bottom:1px solid $gray;
}
.main-register h3 span {
	font-weight: 800;
	font-size: 18px;
	color: $blue-light;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
}
.main-register {
	float:left;
	width:100%;
	position:relative;
	padding:0 0 10px;
	margin-bottom:50px;
	background:$white;
	border-radius:10px;
	overflow:hidden;
	box-shadow: 0px 0px 0px 7px rgba($white,0.3);
}
.close-reg {
	position:absolute;
	top:12px;
	right:10px;
	border-radius:100%;
	color:$white;
	width:30px;
	height:30px;
	line-height:30px;
	cursor:pointer;
	transition: all .2s ease-in-out;
	z-index:100;
	border:1px solid $white;
}
.soc-log {
	padding:15px 0;
}
.soc-log p {
	margin-bottom:12px;
	font-size:12px;
	color:$gray-12;
}
.soc-log a {
	float:left;
	width:100%;
	padding:0 0 0  100px;
	color:$white;
	height:60px;
	line-height:60px;
	margin-bottom:10px;
	border-radius:4px;
	font-size:14px;
	font-weight:500;
	position:relative;
	text-align:left;
}
.soc-log a i {
	font-size:16px;
	position: absolute;
	top:0;
	width:60px;
	height:100%;
	left:0;
	height:60px;
	line-height:60px;
	text-align:center;
	border-right: 1px solid rgba($black,0.21);
}
.soc-log a.facebook-log {
	background:$facebook;
}
.soc-log a.twitter-log {
	background:$twitter;
}
.soc-log a.paypal-log{
	background:$paypal;
}
.log-separator  span{
	position:relative;
	width:90px;
	display:inline-block;
	background:$white;
	z-index:2;
}
.log-separator:before {
	content:'';
	position:absolute;
	top:50%;
	left:0;
	right:0;
	height:1px;
	background:$gray;
	z-index:1;
}
.custom-form .log-submit-btn {
	float:left;
	padding:13px 35px;
	border:none;
	border-radius:4px;
	color:$white;
	cursor:pointer;
	-webkit-appearance: none;
	margin-top:12px;
}
.main-register .filter-tags{
	float:left;
	margin-top:30px;
}
.main-register .filter-tags label{
	color: $gray-3 !important;
}
.lost_password {
	margin-top:34px;
	float:right;
}
.lost_password a  , .main-register .filter-tags{
	float:left;
	color: $gray-3;
	font-size:12px;
	font-weight:600;
}
#tabs-container {
	float: left;
	width: 100%;
	margin-top:10px;
	padding:0 30px;
}
.tab {
	float: left;
	width: 100%;
}
.tabs-menu {
	float:left;
	width:100%;
}
.tabs-menu  li {
	float:left;
	width:50%;
}
.tabs-menu  li a{
	float:left;
	width:100%;
	padding:20px 30px;
	font-weight:600;
	font-size:12px;
	color:$white;
	background:$gray;
	text-align:left;
	text-transform:uppercase;
}
.tabs-menu  li a i {
	padding-right:20px;
}
.tabs-menu  li.current a {
	background:$white;
}
.tabs-menu  li.current a , .tabs-menu  li a:hover {
	color:$white;
}
.tab-content {
	width: 100%;
	display: none;
}
/*-------------Forms---------------------------------------*/
.custom-form {
	float:left;
	/* width:100%; */
	position:relative;
}
.custom-form .nice-select {
	margin-bottom:20px;
}


.label-style{
	float: left;
	width: 100%;
	text-align: left;
	margin-bottom: 10px;
	color: #878c9f;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
}
.custom-form  textarea , .custom-form input[type="text"] , .custom-form  input[type=email] , .custom-form  input[type=password] , .custom-form  input[type=button], .input-style{
	border: 1px solid $gray;
	background:$gray-light;
	width: 100%;
	padding: 14px 10px 14px 10px;
	border-radius: 6px;
	color: $gray-5;
	font-size:13px;
	-webkit-appearance: none;
}
.custom-form  textarea:focus , .custom-form input[type="text"]:focus  , .custom-form  input[type=email]:focus  , .custom-form  input[type=password]:focus  {
	background:$white;
}
.custom-form .nice-select input {
	padding-left:20px;
}
.custom-form  input::-webkit-input-placeholder  , .custom-form  textarea::-webkit-input-placeholder{
	color: $gray-5;
	font-weight:500;
	font-size:13px;
}
.custom-form  input:-moz-placeholder , .custom-form  textarea:-moz-placeholder  {
	color: $gray;
	font-weight:600;
	font-size:13px
}
.custom-form textarea{
	height: 200px;
	resize: none;
	padding: 25px 20px;
}
.custom-form input  {
	margin-bottom:20px;
}
.filter-tags input , .custom-form  .filter-tags input {
	position:relative;
	border: 2px solid $gray-2;
	cursor: pointer;
	padding: 0;
	width: 20px;
	height: 20px;
	position: relative;
	color:$white;
	background:$white !important;
}
/* .filter-tags input:checked:after , .custom-form  .filter-tags input:checked:after  {
	font-family: Font Awesome\ 5 Pro;
	content: "\f00c";
	font-size: 12px;
	position: absolute;
	top:2px;
	left: 2px;
	z-index:20;
} */
.filter-tags label , .custom-form  .filter-tags label  {
	float:left;
	padding:0 10px;
	position:relative;
	color:$gray;
	font-weight:600;
	width:auto;
}
.custom-form label {
	float:left;
	position:relative;
	width:100%;
	text-align:left;
	font-weight:500;
	color:$gray-5;
	color: $gray-dark;
	font-size: 13px;
	font-weight: 500;
 
}
.main-register .custom-form label {
	padding-bottom:12px;
}
.custom-form label i {
	padding-right:12px;
	font-size:14px;
	position:absolute;
	top:16px;
	left:16px;
}
.custom-form button {
	outline:none;
	border:none;
	cursor:pointer;
	-webkit-appearance: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
	-webkit-appearance: none;
	margin: 0;
}
input[type=number]
{
	-moz-appearance: textfield;
}
.quantity {
	float:left;
	width: 65px;
	height: 42px;
	position:relative;
}
.quantity input {
	width: 100%;
	height: 42px;
	line-height: 1.65;
	float: left;
	padding: 0;
	margin: 0;
	padding-left:10px;
	border: 1px solid $gray;
	border-radius:4px 0 0 4px;
	margin-bottom:0;
}
.quantity input:focus {
	outline: 0;
}
.quantity-nav {
	position: absolute;
	height: 42px;
	right:0;
	width: 20px;
	border-right: 1px solid $gray;
}
.quantity-button {
	background: $gray-light;
	position: absolute;
	right:0;
	cursor: pointer;
	border-left: 1px solid $gray;
	width: 20px;
	text-align: center;
	color: $gray-13;
	font-size: 13px;
	font-family: "Trebuchet MS", Helvetica, sans-serif !important;
	line-height: 1.7;
	box-sizing:border-box;
	-webkit-user-select: none;
	user-select: none;
}
.quantity-button.quantity-up {
	position: absolute;
	height: 50%;
	top: 0;
	border-bottom: 1px solid $gray;
	border-top: 1px solid $gray;
}
.quantity-button.quantity-down {
	position: absolute;
	bottom: -1px;
	height: 50%;
	border-bottom: 1px solid $gray;
}
.custom-form.no-icons input , .custom-form.no-icons textarea {
	padding-left:10px;
}
.custom-form.no-icons label {
	margin-bottom:20px;
}
.pass-input-wrap span {
	position: absolute;
	right: 20px;
	cursor: pointer;
	bottom: 36px;
	z-index: 10;
	font-size:12px;
}
.radio{
	color:$gray-4;
	font-size:13px;
	position:relative;
	top:-2px;
}
.radio span{
	position:relative;
	padding-left:30px;
	top:1px;
}
.radio span:after{
	content:'';
	width:20px;
	height:20px;
	border:2px solid $gray-2;
	position:absolute;
	left:0;
	top:-3px;
	box-sizing:border-box;
}
.radio input[type="radio"]{
	cursor: pointer;
	position:absolute;
	width:100%;
	height:100%;
	z-index: 1;
	opacity: 0;
}
.radio input[type="radio"]:checked + span{
	color:$gray-5;
}
.radio input[type="radio"]:checked + span:before{
	font-family: Font Awesome\ 5 Pro;
	content: "\f00c";
	font-size: 13px;
	position: absolute;
	top:0;
	left: 4px;
	z-index:20;
}
.fuzone {
	position: relative;
	border: 1px   solid $gray;
	border-radius: 3px;
	background: $gray-light;
	transition: all 0.3s linear;
	margin-bottom: 10px;
	display: inline-block;
	width: 100%;
	min-height:160px;
	margin-top:0px;
	float:left;
	cursor:pointer;
}
.fuzone input {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	opacity:0;
	z-index:100;
	cursor:pointer;
}
.fuzone .fu-text {
	text-align: center;
	margin: 50px 0;
	font-size: 12px;
	color: $gray-4;
	font-weight:400;
	position: relative;
}
.fuzone .fu-text i {
	font-size:44px;
	width:100%;
	padding-bottom:10px;
	transition: all 0.3s linear;
}
.fuzone:hover  .fu-text i {
	transform: translateY(-10px);
}
.photoUpload-files span {
	color: $gray-5;
	margin: 0 3px;
	padding: 3px 6px;
	background: $white;
	border-radius: 2px;
	display: inline-block;
	font-size: 10px;
	box-shadow: 0 5px 5px rgb(0 0 0 / 7%);
}
.book-form .quantity-item label {
	width:auto;
	position:relative;
	top:16px;
	padding-right:10px;
	color: $gray-dark;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
}
.book-form .quantity-item label i {
	position:relative;
	float:left;
	top:0;
	left:0;
	padding-right:8px;
}
.book-form .quantity  {
	float:right;
}
.book-form .quantity-item {
	margin-bottom:20px;
	float:left;
	width:50%;
}

.book-form  .quantity-item.fcit {
	padding-left:20px;
}
.book-form .btnaplly {
	color:$white;
	width:100%;
	background: $yellow;
	padding:15px 0;
	border-radius:4px;
}
.book-form .btnaplly i {
	padding-left:10px;
}
.total-coast {
	padding:15px 0;
	border-top:1px solid $gray;
	border-bottom:1px solid $gray;
	margin-bottom:20px;
}
.total-coast strong {
	color: $gray-dark;
	font-size: 12px;
	font-weight: 600;
	position: absolute;
	left:0;
	top:21px;
}
.total-coast span {
	float:right;
	text-align:right;
	font-size:19px;
	font-weight:400;
	position:relative;
	top:3px;
}
.custom-form .total-coast  input {
	width: inherit !important;
	float:left;
	border:none;
	background:none;
	height:auto;
	padding:0;
	color: $gray;
	font-size:24px;
	text-align:right;
	margin-bottom:0;
	top:-3px;
	position:relative;
	right:10px;
}
.hid-input {
	display:none;
}
.book-form .bookdate-container label i {
	top:42px;
}
.book-form .bookdate-container input {
	padding-left:42px; 
}
.book-form .listsearch-input-item label   , .book-form .bookdate-container label {
	margin-bottom:12px;
}
.bookdate-container-dayscounter {
	position:absolute;
	right:10px;
	bottom:32px;
	z-index:20;
	cursor:help;
}
.bookdate-container-dayscounter  span{
	color:$white;
	position:absolute;
	right:-10px;
	top:-40px;
	border-radius: 4px;
	background: $blue;
	height: 30px;
	line-height: 30px;
	min-width: 100px;
	font-size:11px;
	opacity:0;
	visibility:hidden; 
}
.bookdate-container-dayscounter:hover span {
	opacity:1;
	visibility:visible;
}
.onoffswitch {
	position: relative; width: 83px;
	-webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
	display: none;
}
.onoffswitch-label {
	display: block; overflow: hidden; cursor: pointer;
	border: 2px solid $white; border-radius: 20px;
}
.onoffswitch-inner {
	display: block; width: 200%; margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
	display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
	font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
	box-sizing: border-box;
}
.onoffswitch-inner:before {
	content: "ON";
	padding-left: 21px;
	background-color: $color-secondary-gradient; color: $white;
}
.onoffswitch-inner:after {
	content: "OFF";
	padding-right: 21px;
	background-color: $color-secondary-gradient; color: $white;
	text-align: right;
}
.onoffswitch-switch {
	display: block; width: 21px; margin: 4.5px;
	background: $white;
	position: absolute; top: 0; bottom: 0;
	right: 49px;
	border: 2px solid $white; border-radius: 20px;
	transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
	right: 0px;
}
/* .main-register .custom-form input[type="text"] , .main-register .custom-form  input[type=email] ,
.main-register .custom-form  input[type=password]{
	padding-left:20px;
} */
/*-------------Map---------------------------------------*/
.map-container {
	width:100%;
	position:relative;
	overflow:hidden;
	transition: height 0.2s ease
}
.fw-map   {
	height:250px;
}
.fw-map.big_map {
	height:calc(100vh - 154px);
}
.fw-map2 #singleMap , .fw-map2{
	height:500px;
}
.hidden-map-container {
	display:none;
	background:$white;
	overflow:hidden;
}
#singleMap   , .fw-map-small-map {
	height:420px;
	margin-bottom:30px;
}
.hidden-map-container #singleMap {
	height:500px;
	margin-bottom:15px;
}
.map-container.column-map.right-pos-map {
	right:0;
}
.map-container.column-map.left-pos-map {
	left:0;
}
.map-container #map-main{
	position: absolute;
	top:0;
	left:0;
	height: 100%;
	width:100%;
	z-index: 990;
	overflow:hidden;
}
.map-container.column-map {
	width:calc(100% - 900px);
	position: absolute;
	overflow:hidden;
} 
.map-close {
	position:absolute;
	left:365px;
	top:10px;
	z-index: 1100;
	cursor: pointer;
	width: 43px;
	height: 43px;
	line-height: 43px;
	border-radius:4px;
	display:none;
	background: $white;
	box-shadow: 0 2px 4px 0 rgba($black,.1);
}
.controls-mapwn {
	background: $white url(../../images/search-icon.png) right no-repeat;
	box-shadow: 0 2px 4px 0 rgba($black,.1);
	width:350px;
	z-index:1000;
	border:none;
	padding:20px;
	border-radius:4px;
}
.controls {
	margin: 10px 0 0 10px;
}
#singleMap    {
	width:100%;
	height:300px;
	float:left;
	margin-bottom:30px;
}
.box-widget #singleMap {
	margin-bottom:10px;
}
.mapzoom-in  , .mapzoom-out{
	position: absolute;
	z-index:100;
	top:50%;
	cursor:pointer;
	width:40px;
	height:40px;
	line-height:40px;
	right:12px;
	margin-top:-84px;
	-webkit-transform: translate3d(0,0,0);
	background: $white;
	box-shadow: 0 2px 4px 0 rgba($black,.1);
}
.mapzoom-out {
	margin-top:-42px;
	border-bottom-left-radius:8px;
	border-bottom-right-radius:8px;
}
.mapzoom-in{
	border-top-left-radius:8px;
	border-top-right-radius:8px;
}
#singleMap .mapzoom-in  , #singleMap .mapzoom-out {
	margin-top:0;
}
#singleMap .mapzoom-in {
	margin-top:-42px;
}
.mapzoom-in:before  , .mapzoom-out:before{
	font-family: Font Awesome\ 5 Pro;
	content: "\f068";
	font-size:10px;
	font-weight:600;
}
.mapzoom-in:before{
	content: "\f067";
}
.mapnavigation {
	position:absolute;
	bottom:50px;
	right:20px;
	z-index:1030;
	width:140px;
	border-radius:4px;
	box-shadow:0px 0px 0px 4px rgba($white,0.3);
	overflow:hidden;
}
.mapnavigation:before {
	content:'';
	position:absolute;
	left:50%;
	width:1px;
	height:100%;
	z-index:10;
	background:$black;
	opacity:0.2;
}
.mapnavigation a {
	width:70px;
	padding:10px 0;
	color:$white;
	float:left;
	font-size:11px;
	background: $blue;
}
.mapnavigation a i {
	padding:0 5px;
	font-size:13px;
	position:relative;
	top:1px;
}
.mapnavigation a:hover i {
	color:$white;
}
.cluster img{
	display:none
}
.cluster{
	width:40px!important;
	height:40px!important;
}
.cluster div{
	text-align:center;
	font-size:15px!important;
	color:$white!important;
	font-weight:600;
	border-radius:100%;
	width:40px!important;
	height:40px!important;
	line-height:38px!important;
	box-shadow: 0px 0px 0px 4px rgba(6,27,65,0.3);
	border:2px solid  $white;
	z-index:100;
	-webkit-transition: all 300ms linear;
	transition: all 100ms 3inear;
	animation:cluster-animation 1.5s infinite;
}
.cluster div:hover {
	background:$gray-3;
}
@keyframes cluster-animation{0%,100%{box-shadow: 0px 0px 0px 4px rgba(6,27,65,0.3);}50%{box-shadow: 0px 0px 0px 9px rgba(6,27,65,0.1);}}
.map-view-wrap {
	position: absolute;
	z-index:211;
	left:0;
	width:100%;
	height:1px;
	top:0;
}
.map-view-wrap_item {
	position:relative;
	float:left;
	width:350px;
	border-radius:6px;
	background:$white;
	padding:25px 30px;
	top:80px;
}
.map-view-wrap .container {
	height:1px;
}
.mar-top {
	margin-top:30px !important;
}
/*------listing------------------------------------------------*/
.grey-blue-bg {
	background: $gray-8;
}
.col-list-wrap {
	position:relative;
	width:900px;
	float:left;
	background:$gray-8;
	min-height: 100vh;
	z-index:50;
	left:0;
	box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.17);
	transition: all .2s ease-in-out;
	padding-bottom:40px;
}
.fw-col-list-wrap {
	width:100% !important;
	box-shadow: 0px 0px 0 0px rgba(0, 0, 0, 0.27);
	background:none;
	padding-bottom:0;
}
.fw-col-list-wrap  .list-main-wrap-opt {
 
	padding:0 8px;
	margin-top:0;
}
.fh-col-list-wrap , .center-col-list-wrap {
	width:100%;
}
.filter-sidebar {
	padding:25px 30px;
	border-radius:10px;
	background:$white;
	border:1px solid $gray;
	z-index:120;
}
.filter-sidebar .col-list-search-input-item {
	margin-bottom:25px;
}
.filter-sidebar .col-list-search-input-item:last-child {
	margin-bottom:0;
}
.filter-sidebar .col-list-search-input-item label {
	margin-bottom:15px;
}
.filter-sidebar .col-list-search-input-item .filter-tags label {
	margin-bottom:0;
}
.filter-sidebar .col-list-search-input-item .header-search-button{
	top:0;
	left:0;
}
.list-wrap-search  {
	padding:25px 0;
	background:$white;
	z-index:60;
	margin-bottom:10px;
}
.list-wrap-search.lisfw {
	margin-bottom:50px;
	border-radius:10px;
}
.search-opt-wrap {
	margin-top:20px;
}
.range-slider-wrap {
	margin-top:6px;
}
.search-opt-container .filter-tags li{
	float:left;
	width:100%;
	margin-bottom:10px;
	text-align:left;
	font-size:11px;
}
.filter-tags .listing-rating   i{
	padding-right:3px;
	font-size:10px;
}
.four-star-rating .listing-rating   i:nth-child(5) ,   .three-star-rating .listing-rating   i:nth-child(4) ,   .three-star-rating .listing-rating   i:nth-child(5) {
	color:$gray-2;
}
.filter-tags .listing-rating span {
	color:$gray-4;
	padding-left:6px;
}
.leave-rating-title {
	margin-right:10px;
	font-weight:500;
	font-size:14px;
	color:$gray-5;
	position:relative;
	top:-3px;
}
.leave-rating input[type="radio"] {
	display: none;
}
.leave-rating label {
	font-size: 12px;
	float: right;
	letter-spacing: 4px;
	color: $yellow;
	cursor: pointer;
	transition: 0.3s;
}
.leave-rating-holder .leave-rating   {
	float:left;
	margin-top:6px;
}
.leave-rating label:hover:before  , .leave-rating label.selected:before , .leave-rating  label.min-star:before {
	font-weight:bold;
}
.leave-rating:hover input[type="radio"]:checked~label:before , .leave-rating input[type="radio"]:checked~label:before  , .leave-rating label:hover~label:before{
	font-weight:bold;
}
.hidden-listing-filter {
	padding:25px 20px 0;
	margin-top:25px;
	border-top:1px solid $gray;
	display:none;
}
.hidden-listing-filter-vis {
	opacity:1
}
.hidden-listing-filter h4 {
	float: left;
	width: 100%;
	text-align: left;
	margin-bottom: 15px;
	color: $gray-dark;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
}
.show-more-filters {
	position: absolute;
	right:0;
	bottom:-53px;
	text-align:left;
	cursor:pointer;
	padding:8px 17px;
	color:$white;
	border-bottom-left-radius:4px;
	border-bottom-right-radius:4px;
	font-size:11px;
	font-weight:600;
	z-index:20;
	transition: all .2s ease-in-out;
}
.show-more-filters i {
	padding-right:10px;
	font-size:9px;
	position:relative;
	top:0;
	font-weight:600;
}
.show-more-filters.active-hidden-opt-btn i {
	padding-right:10px;
	top:3px;
	color:$white;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}
.list-main-wrap-title {
	padding:20px 0;
	margin-top:10px;
	border-bottom:1px solid $gray-11;
}
.list-main-wrap-title.single-main-wrap-title {
	margin-top:0;
	padding-top:0;
	margin-bottom:20px;
	border-bottom:1px solid $gray;
}
.fw-col-list-wrap  .list-main-wrap-opt  .list-main-wrap-title {
	padding:0 0 10px 0;
	margin-top:0;
	margin-bottom:10px;
}
.list-main-wrap-title h2 {
	text-align:left;
	font-weight: 500;
	font-size: 18px;
	float:left;
	font-family: 'Montserrat', sans-serif;
	color: $gray-15;
}
.list-main-wrap-opt {
	margin-top:20px;
}
.fw-col-list-wrap .list-main-wrap-opt .list-main-wrap-title.col-title {
	margin-bottom:20px;
}
.list-main-wrap-opt-sb {
	margin-top:0;
}
.half-tags {
	width:50%;
}
.price-opt  , .price-opt ul  , .price-opt-title , .price-opt ul li{
	float:left;
}
.price-opt {
	position:relative;
	top:10px;
}
.price-opt-title {
	color:$gray-5;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 700;
	float:left;	
}
.price-opt   .listsearch-input-item {
	float:left;
	margin-left:20px;
	position:relative;
	top:-15px;
	width:200px;
}
.price-opt   .listsearch-input-item .nice-select {
	background:$white;
	width:100%;
}
.grid-opt {
	float:right;
}
.grid-opt li {
	float:left;
	margin-left:10px;
}
.grid-opt li span {
	float:left;
	font-size:14px;
	font-weight:900;
	color:$gray-2;
	background:$white;
	width:36px;
	height:36px;
	border-radius:100%;
	border:1px solid $gray;
	line-height:36px;
	cursor:pointer;
}
.listing-item-container {
	margin-top:20px;
	overflow: hidden;
}
.listing-item {
	float:left;
	width:50%;
	padding:0 8px 0 0;
	margin-bottom:12px;
	position:relative;
}
.three-columns-grid .listing-item {
	width:33.3%;
	padding:0 8px !important;
}
.listing-item.has_one_column , .slick-slide-item .listing-item  {
	width:100%;
}
.listing-item.has_one_column  {
	padding:0!important;
}
.listing-item.has_one_column .geodir-category-listing  {
	padding-left:13px !important;
}
.listing-item.has_one_column .geodir-category-img {
	width:40%;
	border-radius: 10px;
	top:12px;
}
.listing-item.has_one_column  .hotel-card .geodir-category-img {
	width:100%;
	border-radius: 10px;
	top:0;
}
.three-columns-grid .listing-item.has_one_column .geodir-category-listing {
	padding-left:0 !important;
}
.three-columns-grid .listing-item.has_one_column .geodir-category-img {
	top:0px; 
}
.listing-item.has_one_column .geodir-category-content {
	width:60%;
}
.slick-slide-item .listing-item  {
	padding:0 10px;
	margin-bottom:0;
}
.geodir-category-listing {
	border-radius:10px;
	background:$white;
	border:1px solid $gray;
}
.geodir-category-content {
	padding:20px;
	z-index:2;
	min-height: 263px;
}
.geodir-category-img {
	float:left;
	width:100%;
	position:relative;
	overflow:hidden;
	z-index:1;
	border-radius:10px 10px 0 0;
}
.geodir-category-img .listing-counter {
	top:20px;
	left:20px;
}
.geodir-category-img .listing-counter strong {
	padding-left:4px;
}
.geodir-category-img img,
.gallery-item img{
	object-fit: cover;
	width:100%;
	height:280px;
	transform: translateZ(0);
}
.geodir-category-img:hover img , .gallery-item:hover img{
	transform: scale(1);
}
.listing-avatar {
	position:absolute;
	top:20px;
	left:20px;
	z-index:4;
}
.listing-avatar img {
	width:34px;
	height:34px;
	border-radius:100%;
	box-shadow: 0px 0px 0px 5px rgba($white,0.2);
}
.avatar-tooltip {
	position:absolute;
	top:2px;
	margin-top:20px;
	opacity:0;
	left:100%;
	margin-left:20px;
	min-width:150px;
	visibility: hidden;
	padding:9px 0;
	border-radius:4px;
	background: $blue;
	color:$white;
	font-size:11px;
	font-weight:600;
	transition: all .2s ease-in-out;
	box-shadow: 0px 0px 0px 5px rgba($white,0.2);
}
.listing-avatar:hover .avatar-tooltip {
	margin-top:0;
	opacity:1;
	visibility:visible;
}
.geodir-category-content p {
	text-align:left;
	font-size:12px;
	color:$gray-4;
}
.geodir-category-content h3 , .cart-details_text .geodir-category-content-title-item h3 {
	float:left;
	text-align:left;
	font-weight:600;
	font-size:16px;
	margin-bottom:10px;
}
.geodir-category-content h3 a,
.geodir-category-content h3 span{
	color: $primary;
}
.geodir-category-content-tags {
	margin-top:10px;
}
.category-name {
	float:left;
	margin-right:10px;
	position:relative;
	color:$gray-4;
	top:-2px;
	border-radius:2px;
	background:$gray;
	padding: 4px 14px;
}
.geodir-category-opt {
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	z-index:3;
	padding:22px 20px;
	cursor:pointer;
}
.geodir-category-opt h4 {
	float:left;
	width:100%;
	text-align:left;
	font-size:16px;
	font-weight:600;
	margin-bottom:1px;
}
.geodir-category-opt h4 a{
	color:$white;
} 
.geodir-category-opt .listing-rating{
	float:left;
	position:relative;
	top:10px;
}
.geodir-category-opt .listing-rating i {
	font-size:10px;
	margin-right:4px;
}
.geodir-category-opt  .rate-class-name{
	float:right;
	position:relative;
	top:-6px;
}
.geodir-category-opt  .rate-class-name .score {
	float:left;
	color:  $white;
	margin-right:10px;
	font-size:11px;
}
.geodir-category-opt  .rate-class-name .score strong {
	display:block;
	text-align:right;
	margin:5px 0 2px;
	font-style:normal;
	color:$white;
	font-size:13px;
	font-family: 'Montserrat', sans-serif;
	font-weight:500;
} 
.geodir-category-opt  .rate-class-name span {
	background: $blue;
	color:$white;
	float:left;
	font-weight:600;
	border-radius: 5px 5px 5px 0;
	padding: 12px;
	box-shadow: 0px 0px 0px 3px rgba($white,0.2);
}
.geodir-category-content-title {
	padding-bottom:20px;
	margin-bottom:10px;
	border-bottom:1px dotted $gray-2;
}

.geodir-category-content-title-item {
	float:left;
	width:100%;
	position:relative;
	z-index:1;
}
.facilities-list {
	padding-top:24px;
	margin: 12px 0 12px 0;
	list-style:none;
	display: flex;
}
.facilities-list li {
	margin-right:20px;
	cursor:pointer;
	position:relative;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.facilities-list li i {
	color:$gray-10;
	font-size:16px;
}
.facilities-list li .facility-tooltip {
	position:absolute;
	opacity:0;
	left:-45px;
	top:-34px;
	min-height:26px;
	line-height:26px;
	min-width:120px;
	color:$white;
	font-size:11px;
	visibility: hidden;
	border-radius:4px;
	background: $blue;
	padding-left: 10px;
	padding-right: 10px;
	white-space: nowrap;
}
.facilities-list li:hover .facility-tooltip {
	visibility:visible;
	opacity:1;
}
.facilities-list li .facility-count {
	margin-top: 3px;
	margin-left: 3px;
}
.geodir-category-footer{
	margin:4px 0 0 0;
	padding-top: 18px;
	border-top:1px dotted $gray-2;
}

.geodir-category-location a {
	float:left;
	text-align:left;
	color:$gray-4;
	font-weight:600;
	text-transform:uppercase;
	font-size:11px;
}
.geodir-category-location a i {
	padding-right:6px;
}

.geodir-opt-list a {
	display: inline-block;
	margin-left:5px;
	margin-bottom:3px;
	background: $secondary;
	line-height:32px;
	position:relative;
	color:$white;
	border-radius:4px;
	font-size:12px;
	padding: 4px 12px;

	span {
		margin-right: 10px;
	}
}
.geodir-opt-list a:hover i,
.geodir-opt-list a:hover span,
.show-search-button:hover i{
	transition: all .2s ease-in-out;
	animation: bounce 1s;
	color: $white;
}
@keyframes bounce {
	0%, 20%, 60%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
	80% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}
.geodir-opt-link {
	padding-right:6px;
}
.geodir-opt-link a {
	float:left;
	height:36px;
	line-height:36px;
	color: $white;
	background: $gray-8;
	width:100%;
	font-weight:500;
	border-radius:4px;
	text-align:left;
	padding-left:20px;
}
.geodir-opt-link a:hover{
	background: $blue;
}
.geodir-opt-link a i {
	position:absolute;
	right:0;
	width:40px;
	height:36px;
	line-height:36px;
	top:0;
	font-size:8px;
	border-left: 1px solid rgba($white,0.21);
	text-align: center;
}
.geodir-opt-link a:hover {
	color:$white;
} 
.geodir-opt-tooltip {
	position:absolute;
	opacity:0;
	right:0;
	top:-40px;
	height:30px;
	line-height:30px;
	min-width:100px;
	margin-left:-75px;
	color:$white;
	font-size:11px;
	visibility: hidden;
	border-radius:4px;
}
.geodir-opt-tooltip strong {
	color:$white;
	padding-left:6px;
}
.geodir-opt-list a:hover .geodir-opt-tooltip {
	opacity:1;
	visibility:visible;
}
.sale-window {
	position:absolute;
	right:20px;
	top:20px;
	z-index:3;
	background:$green-light;
	padding:8px 18px;
	border-top-right-radius:4px;
	border-bottom-right-radius:4px;
	font-size:11px;
	color:$white;
	-webkit-transform: translate3d(0,0,0);
}
.sale-window:before , .sale-window:after {
	content: '';
	position:absolute;
	width: 0;
	height: 0;
	left:0;
}
.sale-window:after {
	border-bottom: 14px solid $green-light;
	border-left: 16px solid transparent;
	margin-left: -16px;
	margin-top: 6px;
}
.sale-window:before  {
	border-top: 14px solid $green-light;
	border-left: 16px solid transparent;
	margin-left: -16px;
	margin-top: -8px;
}
.sale-window.big-sale:after {
	border-bottom: 14px solid $secondary;
}
.sale-window.big-sale:before {
	border-top: 14px solid $secondary;
}
.sale-window.big-sale {
	background:$secondary;
}
.map-popup-wrap {
	display:block;
	width:300px !important;
	position:relative;
}
.map-popup {
	display:block;
	width:100%;
	position:relative;
	overflow:hidden;
	border-radius:10px;
	background:$white;
	box-shadow: 0 9px 16px rgba(58, 87, 135, 0.15);
}
.map-popup-wrap:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	margin-top:-1px;
	z-index:20;
}
.map-popup-wrap:before {
	border-color:  transparent;
	border-top-color: $white;
	border-width: 15px;
	margin-left: -15px;
}
.map-popup img {
	width:100%;
	height:auto;
}
.listing-content {
	background:$white;
	padding:20px 20px;
	z-index:20;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
}
.listing-content:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f35d";
	position:absolute;
	color:$gray-2;
	top:20px;
	right:20px;
	font-size:25px;
	font-weight:500;
	z-index:-1;
	opacity:0.5;
}
.listing-title h4 {
	font-size:16px;
	text-align:left;
	font-weight:500;
}
.map-popup-location-price {
	position:absolute;
	left:20px;
	top:24px;
	padding:6px 7px;
	font-size:12px;
	z-index:20;
	font-weight:700;
	border-radius:4px;
	overflow:hidden;
}
.map-popup-location-price strong {
	color:$white;
	float:left;
	width:100%;
	margin-bottom:4px;
	font-size:11px;
	font-weight:400;
}
.map-popup-location-info {
	float:left;
	width:100%;
	margin-top:10px;
	color: $gray-dark;
	font-weight: 500;
	font-size: 12px;
	text-align:left;
}
.map-popup-location-info i{
	padding-right:6px;
}
.map-card-rainting {
	position:absolute;
	left:0;
	width:100%;
	top:-40px;
	padding:15px 20px;
	z-index:2;
}
.map-popup-location-price:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	background: $blue-dark;
	opacity: 0.5;
}
.map-card-rainting i {
	float:left;
	margin-right:4px;
}
.infoBox-close {
	position:absolute;
	width:36px;
	height:36px;
	line-height:36px;
	top:30px;
	right:20px;
	color:$white;
	border-radius:6px;
	z-index:20;
	cursor:pointer;
	-webkit-transition: all 300ms;
	transition: all 300ms;
}
.infoBox-close:hover , .header-search-button:hover i , .contact-btn:hover i  , .header-sec-link  a:hover i{
	animation: bounce 1s;
}
.pagination {
	margin-top:30px;
	text-align:center;
	float:left;
	width:100%;
}
.pagination  a {
	display:inline-block;
	width:44px;
	height:44px;
	line-height:44px;
	border-radius:6px;
	border:1px solid $gray;
	box-sizing:border-box;
	position:relative;
	font-size:13px;
	color:$gray;
	background:$white;
	letter-spacing:1px;
}
.pagination a.current-page, .pagination a:hover {
	color:$white;
}
.pagination  a i {
	font-size:12px;
}

.pagination .active span {
	display:inline-block;
	width:44px;
	height:44px;
	line-height:44px;
	border-radius:6px;
	border:1px solid $gray;
	box-sizing:border-box;
	position:relative;
	font-size:13px;
	color:$white;
	background:$yellow;
	letter-spacing:1px;
}

.back-to-filters {
	position:absolute;
	right:-40px;
	width:40px;
	height:40px;
	top:15px;
	font-size:14px;
	color:$white;
	line-height:40px;
	z-index:-1;
	background: $blue;
	border-top-right-radius:6px;
	border-bottom-right-radius:6px;
}
.back-to-filters span {
	visibility:visible;
	position:absolute;
	height:40px;
	min-width:150px;
	line-height:40px;
	visibility:hidden;
	opacity:0;
	top:0;
	font-size:12px;
	left:-150px;
	margin-top:20px;
	-webkit-transition: all 300ms;
	transition: all 300ms;
	font-weight:500;
	border-top-left-radius:6px;
	border-bottom-left-radius:6px;
	background: $blue;
}
.back-to-filters:hover span {
	margin-top:0;
	visibility:visible;
	opacity:1;
}
.back-to-filters:hover  i , .wishlist-link:hover i{
	animation: bounce 1s;
}
.slideshow-container {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:110%;
	z-index:1;
}
.slideshow-item  , .slideshow-container .slick-slide{
	position:relative;
	float:left;
	width:100%;
	height:100%;
} 
.slick-dots {
	position:absolute;
	bottom:120px;
	width:100%;
	left:0;
	z-index:100;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 3px;
	padding: 0;
	cursor: pointer;
}
.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 6px;
	height: 6px;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: none;
	background:  rgba($white,0.41);
	border-radius:100%;
}
.map-modal-wrap {
	position:fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index:1000;
	display: none;
	overflow:auto;
	-webkit-transform: translate3d(0,0,0);
}
.map-modal-wrap-overlay {
	position:fixed;
	top:0;
	left:0;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index:1;
	background: rgba($black,0.41);
	-webkit-transform: translate3d(0,0,0);
}
.map-modal-item {
	position:relative;
	max-width:650px;
	margin:0 auto;
	top:25%;
	z-index:2;
}
.map-modal-container{
	padding:0;
	background:$white;
	border-radius:10px;
	overflow:hidden;
	box-shadow: 0px 0px 0px 7px rgba($white,0.2);
}
.map-modal-container h3 {
	float:left;
	text-align:left;
	font-size:15px;
	font-weight:600;
	margin:25px 0;
	color:$gray-5;
 
	padding-left:30px;
}
.map-modal-container h3 i {
	padding-right:10px;
}
.map-modal #singleMap {
	margin-bottom:0;
}
 
.map-modal-close {
	position:absolute;
	right:30px;
	bottom:16px;
	width:36px;
	height:36px;
	line-height:38px;
	border-radius:4px;
	color:$white;
	z-index:10;
	cursor:pointer;
}
.list-single-hero {
	padding:250px 0 0;
	overflow:hidden;
}
.list-single-hero-title {
	z-index:2;
	padding:40px 0 30px;
}
.list-single-hero-title  , .map-card-rainting , .geodir-category-opt , .listing-item-cat , .ajax-modal-title  {
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(6,27,65,0)), color-stop(100%,rgba(6,27,65,0.95)));
	background: -webkit-linear-gradient(top, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
	background: -o-linear-gradient(top, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
	background: -ms-linear-gradient(top, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
	background: linear-gradient(to bottom, rgba(6,27,65,0) 0%,rgba(6,27,65,0.95) 100%);
}
.list-single-hero-title h2 {
	text-align:left;
	float:left;
	width:100%;
	z-index:2;
	color:$white;
	font-size:44px;
	font-weight: 600;
	position:relative;
	font-family: 'Montserrat', sans-serif;
}
.list-single-hero-title h2:before {
	content:'';
	position:absolute;
	left:0;
	bottom:-20px;
	width:40px;
	height:4px;
	border-radius:4px;
}
.listing-rating-wrap{
	float:left;
	margin-bottom:10px;
}
.list-single-hero-title .listing-rating i {
	font-size: 15px;
	margin-right: 4px;
}
.listing-rating-wrap .listing-rating , .listing-rating-wrap span {
	float:left;
}
.listing-rating-wrap span {
	color:$white;
	padding-right:20px;
	font-size:13px;
	font-weight:600
}
.list-single-header-contacts {
	margin-top:40px;
}
.list-single-header-contacts li{
	float:left;
	margin-right:18px;
}
.list-single-header-contacts li a {
	color:$white;
	font-size:12px;
}
.list-single-header-contacts li  i {
	padding-right:10px;
}
.list-single-hero-details {
	margin-top:6px;
}
.list-single-hero-rating {
	float:right;
	position:relative;
	z-index:1;
}
.list-single-hero-rating .rate-class-name {
	float:right;
	cursor:pointer;
}
.list-single-hero-rating .rate-class-name span {
	background: $blue;
	color: $white;
	float: left;
	font-weight: 600;
	border-radius: 7px 7px 7px 0;
	padding: 22px;
	font-size:24px;
	border: 1px solid rgba($black,0.21);
	text-shadow: 0px 2px 5px  rgba($black,0.21);
}
.list-single-hero-rating .score {
	float:left;
	color:$white;
	text-align:right;
	padding-right:15px;
	margin-top:20px;
}
.list-single-hero-rating .score strong {
	width:100%;
	float:left;
	padding-bottom:4px;
}
.list-single-hero-rating-list {
	position:absolute;
	bottom:100%;
	margin-bottom:20px;
	right:0;
	z-index:10;
	background:rgba(6,27,65,0.45);
	padding:15px;
	overflow:hidden;
	border-radius:4px;
	transition: all .2s ease-in-out;
	visibility:hidden;
	opacity:0;
}
.list-single-hero-rating:hover .list-single-hero-rating-list {
	visibility: visible;
	opacity:1;
	margin-bottom:10px;
}
.list-single-hero-rating-list .rate-item {
	margin-bottom:10px;
}

.flat-hero-container {
	background:$white;
	padding: 25px 30px 30px;
	border-radius:4px;
}
.flat-hero-container  .listing-rating-wrap{
	margin:0;
	top:11px;
	position:relative;
}
.flat-hero-container .box-widget-item-header {
	margin-bottom:10px;
	padding-bottom:30px;
}
.flat-hero-container .box-widget-item-header h3 {
	font-size:18px;
} 
.flat-hero-container .list-single-hero-price  , .list-single-hero-header .list-single-hero-price{
	color:$gray-5;
	font-size:14px;
	margin-top:0;
	margin-bottom:15px;
	border-bottom:1px solid $gray;
	padding:5px 0 20px;
	text-align:left;
}
.list-single-hero-header .list-single-hero-price {
	padding:25px 30px;
	margin-bottom:0;
	border:none;
	background: $gray-8;
	color: $gray-4;
	border-radius: 4px;
	text-transform: uppercase;
	font-size: 11px;
}
.list-single-hero-header .list-single-hero-price span {
	padding-left:50px;
}
.flat-hero-container .list-single-hero-price:before , .list-single-hero-header  .list-single-hero-price:before{
	font-family: Font Awesome\ 5 Pro;
	content: "\f4c0";
	position: absolute;
	color: $gray-2;
	top: 1px;
	font-size: 35px;
	right: 0px;
	opacity: 0.4;
	font-weight: 500;
}
.list-single-hero-header  .list-single-hero-price:before {
	top: 1px;
	font-size: 55px;
}
.list-single-hero-header .geodir-category-location{
	margin-top:15px;
}
.flat-hero-container .review-score-detail  {
	width:100%;
	padding:0;
}
.flat-hero-container .rate-class-name-wrap {
	margin-bottom:20px;
	border-bottom:1px solid $gray;
	padding:0px 0 20px;	
}
.flat-hero-container .rate-class-name-wrap a {
	float:right;
	width:40%;
	padding:14px 0;
	border-radius:4px;
	font-size:11px;
	position:relative;
	top:18px;
	color: $white;
	font-weight:400;
}
.flat-hero-container .rate-class-name-wrap a i {
	padding-left:10px;
}
.flat-hero-container .rate-class-name {
	float:left;
	width:60%;
}
.flat-hero-container .rate-class-name span  {
	background: $blue;
	color: $white;
	float: left;
	font-weight: 600;
	border-radius: 7px 7px 7px 0;
	padding: 22px;
	font-size: 24px;
	border: 1px solid rgba($black,0.21);
	text-shadow: 0px 2px 5px rgba($black,0.21);
	margin-right:10px;
}
.flat-hero-container .rate-class-name .score {
	text-align:left;
	padding-left:18px;
	color:$gray-5;
	font-size:12px;
 
}
.flat-hero-container .rate-class-name .score strong{
	display:block;
	padding:18px 0 6px 0;
}
.flat-hero-container .reviews-score-wrap{
	padding: 0 0 15px;
	margin-bottom:10px;
}
.review-score-detail-list .rate-item {
	margin-bottom:11px;
}
.rate-item-title {
	margin-bottom:6px;
	text-align:left;
	color:$white;
	font-size:12px;
}
.review-score-detail-list .rate-item-title , .review-score-detail-list .rate-item-percent {
	color:$gray-5;
}
.rate-item-bg {
	height:6px;
	background:$white;
	border-radius:8px;
	float:left;
	width:calc(100% - 30px);
	overflow:hidden;
}
.review-score-detail-list .rate-item-bg {
	background:$gray;
	height:10px;
}
.rate-item-line {
	float:left;
	height:6px;
	border-radius:4px;
}
.review-score-detail-list .rate-item-line{
	height:10px;
}
.rate-item-percent {
	position:absolute;
	top:14px;
	right:0;
	color:$white;
}
.list-single-hero-links {
	float:right;
	position:relative;
	top:20px;
}
.lisd-link {
	float:left;
	margin-left:10px;
	padding:10px 20px;
	background:$secondary;
	color:$white;
	border-radius:4px;
	cursor:pointer;
	font-size:12px;
}
.lisd-link i {
	padding-right:10px;
}

.list-single-main-container {
	padding-left:60px;
}
.list-single-main-item {
	padding:30px 30px;
	background:$white;
	margin-bottom:20px;
	border-radius:4px;
	border:1px solid $gray-7;
}
article .list-single-main-item  , article.post-article .list-single-main-item:last-child{
	margin-bottom:0;
}
article.post-article .list-single-main-item {
	margin-bottom:20px;
}
.list-single-main-item-title  h3{
	color: $blue;
	text-align: left;
	font-size: 18px;
	font-weight: 600;
}
.list-single-main-item-title  h3 a{
	color: $blue-2;
}
.list-single-main-item-title {
	margin:0 0 25px 0;
	padding-bottom:25px;
	border-bottom:1px solid $gray;
}
.list-single-hero-header .list-single-main-item-title {
	border-bottom:0;
	margin-bottom:0;
	padding-bottom:0;
}
.list-single-main-item-title .listing-rating {
	float:left;
	margin-top:10px;
}
.list-single-hero-header .list-single-main-item-title  h3 {
	font-size:24px;
}

.list-single-hero-header .list-single-main-item-title:before {
	display:none;
}
.list-single-main-item-title.no-dec-title {
	border:none;
	margin-bottom:0;
	padding-bottom:15px;
}
.list-single-main-item-title.no-dec-title:before {
	display:none;
}
.list-single-main-item p {
	text-align:left;
	color:$gray-dark;
}
.listing-features li{
	float:left;
	width:33.3%;
	text-align:left;
	margin-bottom:15px;
	font-size:13px;
	color: $gray-15;
	font-weight: 800;
	position:relative;
	padding-left:30px;
}
.listing-features li i {
	position:absolute;
	left:0;
	top:0;
}
.tags-stylwrap  a{
	float:left;
	padding:10px 25px;
	border-radius:2px;
	color: $gray;
	font-size: 12px;
	background: $gray-8;
	font-weight:500;
	margin-right:6px;
}
.tags-stylwrap.sb-tags a {
	color:$white;
	margin-bottom:6px;
}
.box-widget-item {
	margin-bottom:30px;
}
.box-widget-item-header {
	padding: 0 0 20px;
	margin: 0 0 25px;
	float:left;
	width:100%;
	border-bottom: 1px solid $gray;
	position:relative;
}
/* .box-widget-item-header:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f0d7";
	position:absolute;
	right:0;
	top:2px;
  color: $gray-10;
} */
.box-widget-item-header h3 {
	text-align: left;
	font-size: 16px;
	font-weight: 600;
	color: $blue;
}
.box-widget-item-header h3 i {
	padding-right:12px;
	font-size:16px;
	color:$gray-4;
}
.box-widget {
	background:$white;
	border-radius:4px;
	border:1px solid $gray;
	float:left;
	width:100%;
}
.no-bor-rad {
	border-radius:0;
}
.box-widget-content {
	float:left;
	width:100%;
	position:relative;
	padding:25px 30px 30px;
}
.box-widget-content.no-pad-bottom {
	padding-bottom:0;
}
#weather-widget {
	float:left; 
	width: 100%;
	border-radius:4px;
	overflow:hidden;
}
.box-widget-list {
	display:block;
}
.box-widget-list  i {
	padding-right:10px;
}
.box-widget-list  li {
	border-bottom:1px solid $gray;
	padding-bottom:15px;
	margin-bottom:15px;
	color:$gray-5;
	position:relative;
	width:100%;
	text-align:left;
	font-size:12px;
}
.box-widget-list  li a {
	margin-left: 5px;
	color: $gray-dark;
}
.list-widget-social {
	float:left;
	margin-top:10px;
}
.list-widget-social li{
	float:left;
	margin-right:10px;
}
.list-widget-social li a {
	float:left;
	width:36px;
	height:36px;
	line-height:36px;
	border-radius:4px;
	color:$white;
	font-size:12px;
	font-weight:300;
}
.banner-wdget {
	overflow:hidden;
	border-radius:6px;
}
.banner-wdget .overlay {
	opacity:0.3;
}
.banner-wdget-content {
	padding:90px 30px 70px;
	z-index:10;
}
.banner-wdget-content h4 {
	font-weight:500;
	color:$white;
	font-size:18px;
}
.banner-wdget-content h4 span{
	color:$green-light;
	font-weight:400;
}
.banner-wdget-content a {
	font-weight: 600;
	color:$white;
	padding:12px 30px;
	display:inline-table;
	margin-top:15px;
	border-radius: 6px;
	cursor: pointer;
	box-shadow: 0px 0px 0px 7px rgba($white,0.2);
}
.banner-wdget-content a:hover{
	background: $blue-4;
}
.banner-wdget-content .countdown-item {
	width:25%;
	float:left;
	color:$white;
}
.banner-wdget-content .countdown-item p {
	font-size:10px;
	color:$white;
}
.banner-wdget-content .countdown-item span {
	font-size:24px;
	font-weight:700;
	font-style:italic;
}
.banner-wdget-content .countdown {
	margin-top:15px;
}
.list-single-main-media {
	overflow:hidden;
	margin-bottom:20px;
}
.box-widget-author-title {
	border-bottom:1px  solid $gray;
	margin-bottom:10px;
	padding:0 0 40px 80px;
}
.box-widget-author-content {
	padding:10px 0 0;
	margin-bottom:10px;
	border-bottom:1px  solid $gray;
}
.box-widget-author-content p {
	text-align:left;
}
.box-widget-author:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f4fc";
	position:absolute;
	color:$gray-2;
	top:10px;
	font-size:35px;
	right:0px;
	opacity:0.4;
	font-weight:500;
}
.box-widget-author-title a {
	float:left;
	text-align:left;
	color:$gray-5;
	position:relative;
	font-size:14px;
	margin-top:10px;
	padding-bottom:6px;
}
.box-widget-author-title span {
	float:left;
	width:100%;
	font-size:10px;
	text-align:left;
	color:$gray-4;
	text-transform:uppercase;
}
.box-widget-author-title-img {
	position:absolute;
	left:0;
	width:60px;
	top:0;
	height:100%;
}
.box-widget-author-title-img img {
	width:60px;
	height:60px;
	border-radius:100%;
}
.box-widget.widget-posts li {
	padding: 8px 0;
	margin-bottom:19px;
	border-bottom:1px  solid $gray;
}
.box-widget.widget-posts li:last-child {
	margin-bottom:0;
}
.box-widget.widget-posts .widget-posts-img {
	float:left;
	width:28%;
}
.box-widget.widget-posts .widget-posts-img  img{
	border-radius:4px;
	width:100%;
	height:auto;
}
.widget-posts .widget-posts-text {
	padding: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: initial;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.box-widget.widget-posts .widget-posts-descr{
	float:left;
	width:66%;
	margin-left:6%;
	text-align:left;
	padding:10px;
	background:$gray-7;
	border-radius:4px;
}
.box-widget.widget-posts .widget-posts-descr a{
	font-size:13px;
	font-weight:500;
	padding-bottom:20px;
	color: $gray-dark;
}
.box-widget.widget-posts   .widget-posts-date  {
	float:left;
	width:100%;
	margin-top:14px;
	font-weight: 600;
	color: $gray-4;
	font-size:11px;
}
.box-widget.widget-posts   .widget-posts-date i {
	padding-right:10px;
}
.box-image-widget {
	float:left;
	width:100%;
	position:relative;
	margin-bottom:20px;
	padding-bottom:10px;
	border-bottom:1px solid $gray;
}
.box-image-widget:last-child {
	margin-bottom:0;
}
.box-image-widget-media {
	float:left;
	width:35%;
}
.box-image-widget-media a{
	float:left;
	width:100%;
	margin-top:8px;
	padding:8px 0;
	color:$white;
	border-radius:2px;
	font-size:11px;
}
.box-image-widget-details {
	float:left;
	width:65%;
	padding-left:18px;
	text-align:left;
}
.box-image-widget-details h4 {
	padding-bottom:6px;
	color:$gray-5;
}
.box-image-widget-details h4 span {
	color: $green-light;
	font-size:11px;
	padding-left:20px;
}
.box-image-widget-details p {
	font-size:12px;
}
.box-image-widget-media img {
	width:100%;
	height:auto;
}
.pricerange  span{
	float:left;
	margin-right: 20px;
	color: $gray-dark;
	font-weight:700;
}
.pricerange {
	font-size:14px;
	text-align:left;
	font-weight:600;
	color: $green-light;
}
.claim-widget-link {
	padding-top:20px;
	margin-top:20px;
	border-top:1px solid $gray;
}
.claim-widget-link span , .claim-widget-link a {
	float:left;
	text-align:left;
	font-weight:500;
}
.claim-widget-link a:hover{
	color: $blue-4;
}
.claim-widget-link span {
	color: $gray-dark;
	margin-right:20px;
}
.rooms-item {
	margin-bottom:25px;
	border-bottom:1px solid $gray;
}
.rooms-item:last-child {
	margin-bottom:0;
}
.rooms-media {
	float:left;
	width:40%;
	position:relative;
	overflow:hidden;
	border-radius:6px 6px 6px 0;
}
.rooms-media img{
	width:100%;
	height:auto;
}
.rooms-details {
	float:left;
	width:60%;
	padding:5px 0 10px  20px;
}
.rooms-details-header {
	text-align:left;
	padding-bottom:20px;
}
.rooms-price {
	position:absolute;
	right:0;
	top:0;
	color:$green-light;
	font-size:19px;
	font-weight: 600;
}
.rooms-price strong {
	padding-left:3px;
	font-size:10px;
	color:$gray-4;
}
.rooms-details-header h3 {
	color: $blue-2;
	padding-bottom:6px;
	font-size:16px;
	font-weight:700;
}
.rooms-details-header h5 {
	font-size:11px;
	font-weight:700;
}
.rooms-container {
	margin-top:20px;
}
.rooms-details .facilities-list{
	margin-top:20px;
	margin-bottom:0;
}
.facilities-list .btn{
	float:right;
	margin:0;
	top:-18px;
	height:40px;
	line-height:40px;
}
.facilities-list .btn i {
	height:40px;
	line-height:40px;
}
.list-single-facts {
	background:$white;
}
.single-facts {
	margin-top:20px;
	padding-top:20px;
	border-top:1px solid $gray;
}
.single-facts .inline-facts-wrap {
	width:25%;
	float:left;
	padding:15px;
}
.single-facts .inline-facts {
	position:relative;
	padding:20px 30px;
	background: $gray-8;
	border-radius:6px;
}
.single-facts .inline-facts i {
	font-size:54px;
	position:absolute;
	right:10px;
	top:10px;
	opacity:0.4;
	z-index:1;
}
.single-facts .inline-facts .num {
	font-size:34px;
	color:$gray-4;
	font-weight:700;
	text-align:left;
}
.single-facts .inline-facts h6 {
	text-align:left;
	color: $gray-15;
	font-weight:300;
	padding-top:10px;
}
.home-facts .inline-facts .num , .single-facts .inline-facts h6 {
	position:relative;
	z-index:2;
}
.home-facts   {
	float:left;
	width:25%;
	padding:0 25px;
}
.home-facts   .num {
	color:$white;
	font-family: 'Montserrat', sans-serif;
	font-size:44px;
}
.home-facts   h6 {
	color: rgba($white,0.71);
	font-size: 13px;
	font-weight:500;
}
.home-facts   h6 {
	margin-top:20px;
	font-size:15px;
	font-weight:500;
	position:relative;
	color: rgba($white,0.91);
}
.home-facts   h6:before {
	content:'';
	position:absolute;
	top:-12px;
	left:50%;
	height:1px;
	background:$white;
	margin-left:-25px;
	opacity:0.4;
	width:50px;
}
.list-single-facts .inline-facts-wrap {
	width:25%;
	float:left;
	padding:20px 0;
	border-left:1px solid $gray-8;
	box-sizing:border-box;
	background:$gray-7;
}
.list-single-facts .inline-facts-wrap:first-child {
	border:none;
}
.list-single-facts .inline-facts-wrap i {
	font-size:34px;
	width:100%;
	padding-bottom:10px;
}
.list-single-facts .inline-facts-wrap .milestone-counter , .list-single-facts .inline-facts-wrap h6 {
	display:inline-block;
	color: $gray-15;
}
.list-single-facts .inline-facts-wrap h6 {
	padding-left:4px;
	color:$gray-4;
	font-size:12px;
} 
.cart-detai ls {
	background:$gray-7;
}
.cart-details_text {
	float:left;
	width:100%;
	border:1px solid $gray;
	border-top:none;
}
.cart-details_header {
	border:1px solid $gray;
	display:inline-table;
	padding:15px 20px;
}
.cart-details_text .geodir-category-content-title-item h3 a {
	color:$gray-5;
}
.cart_list li{
	text-align:left;
	padding:15px 20px;
	color:$gray-5;
	font-size:12px;
	border-bottom:1px solid $gray;
 
}
.cart_list li:last-child {
	border-bottom:0;
}
.cart_list li span , .cart-total strong  {
	float:right;
}
.cart_list li span strong{
	padding-left:6px;
	color: $green-light;
}
.cart-total {
	padding:15px 20px;
	background: $gray-light;
	float:left;
	width:100%;
	border:1px solid $gray;
	border-radius:6px;
}
.cart-details {
	margin-bottom:15px;
}
.cart-total span {
	float:left;
	color: $gray-dark;
	font-size: 16px;
	font-weight: 600;
	position:relative;
	top:2px;
}
.cart-total strong {
	font-size: 20px;
	font-weight: 400;
}
#progressbar {
	margin-bottom: 30px;
	counter-reset: step;
	width:100%;
	float:left;
	box-sizing:border-box;
}
#progressbar li {
	list-style-type: none;
	color:$gray-5;
	font-size:12px;
	width: 25%;
	float: left;
	text-align:left;
	position: relative;
	padding:0 0 25px;
}
#progressbar li span {
	padding-right:10px;
}
#progressbar li:after {
	content: '';
	position:absolute;
	bottom:0;
	width:100%;
	left:0;
	height:4px;
	border-radius:4px;
	background:$gray;
	z-index:1;
}
#progressbar li:before {
	content: '';
	position:absolute;
	bottom:0;
	width:0;
	left:0;
	height:4px;
	z-index:2;
	border-radius:4px;
	transition: all 200ms linear;
}
#progressbar li.active:before {
	width:99%;
}
.bookiing-form-wrap .custom-form form fieldset:not(:first-of-type) {
	display: none;
}
.next-form {
	float:right;
}
.previous-form {
	float:left;
	padding: 0 30px 0 80px;
	height: 44px;
	line-height: 44px;
	border-radius: 4px;
	color: $white;
	font-weight: 600;
	font-size: 12px;
	text-align: left;
	position: relative;
	overflow: hidden;
}
.previous-form i {
	position: absolute;
	left: 0;
	width: 50px;
	height: 44px;
	line-height: 44px;
	border-right: 1px solid rgba($white,0.21);
	text-align: center;
	top: 0;
}
.previous-form:hover:after {
	width:100%;
}
.previous-form:after {
	content: '';
	position: absolute;
	width: 50px;
	height: 100%;
	background: rgba($white,0.2);
	z-index: 0;
	left: 0;
	top: 0;
	transition: all 200ms linear;
}
.success-table-header {
	text-align:left;
	color:$gray-5;
	font-size:16px;
	font-weight:400;
	padding:10px 0 0 70px;
}
.success-table-header i.decsth {
	color:$green-light;
	position:absolute;
	font-size:54px;
	left:0;
	top:0;
}
.success-table-header h4 , .success-table-header p {
	float:left;
}
.success-table-header a {
	font-size:12px;
	color:$white;
	padding:12px 25px;
	float:right;
	border-radius:30px;
	position:relative;
	top:-14px;
}
.success-table-header a i {
	padding-right:8px;
	font-size:15px;
}
.log-massage {
	padding:10px 0;
	text-align:left;
	font-size:12px;
	color:$gray-5;
}
/*------ Reviews------------------------------------------------*/
.reviews-score-wrap{
	padding:0 0 25px;
	border-bottom:1px solid $gray;
	margin-bottom:20px;
}
.list-single-hero-header .reviews-score-wrap  {
	padding:0;
	border-bottom:none;
	margin-bottom:0;
}
.review-score-total , .review-score-detail {
	float:left;
	width:25%;
}
.review-score-total span {
	background: $blue;
	color: $white;
	float: left;
	font-weight: 600;
	border-radius: 7px 7px 0 7px;
	padding: 22px 0;
	width:100%;
	font-size: 34px;
	border: 1px solid rgba($black,0.21);
	text-shadow: 0px 2px 5px rgba($black,0.21);
}
.review-score-total a {
	float:left;
	width:100%;
	padding:10px 0;
	color:$white;
	margin-top:10px;
	border-radius:4px;
}
.review-score-total span strong {
	display:block;
	font-size:14px;
	font-weight: 500;
	padding-top:6px;
}
.review-score-detail {
	width:75%;
	padding-left:20px;
}
.reviews-comments-wrap {
	display: inline-table  ;
	margin-top:30px;
}
.reviews-comments-item-text .review-score-user{
	float:right;
	position:relative;
	top:-12px;
}
.reviews-comments-item-text .review-score-user span  {
	background: $blue;
	color: $white;
	float: right;
	font-weight: 600;
	border-radius: 3px 3px 0 3px;
	width:40px;
	height:40px;
	line-height:40px;
	font-size: 12px;
	border: 1px solid rgba($black,0.21);
	text-shadow: 0px 2px 5px rgba($black,0.21); 
}
.reviews-comments-item-text .review-score-user strong {
	margin-right:12px;
	font-size:11px;
	color:$gray-5;
	position:relative;
	top:10px;
}
.reviews-comments-item {
	padding:0 0 30px 110px;
	position:relative;
	float:left;
	margin-bottom:40px;
	width:100%;
	border-bottom:1px solid $gray;
}
.reviews-comments-item.reply-comment-item {
	width:90%;
	margin-left:10%;
	padding:30px 20px 30px 110px;
	border-radius:6px;
}
.reviews-comments-item.reply-comment-item .review-comments-avatar {
	top:30px;
}
.reviews-comments-item.reply-comment-item:before {
	content:'';
	position:absolute;
	top:0;
	left:-10%;
	margin-left:30px;
	width:40px;
	height:40px;
	border-left:1px solid $gray;
	border-bottom:1px solid $gray;
}
.reviews-comments-item:last-child {
	border:none;
	margin-bottom:0;
}
.review-comments-avatar {
	position:absolute;
	top:0;
	left:10px;
	width:80px;
	height:80px;
}
.review-comments-avatar:before {
	content: '';
	position: absolute;
	top: 100%;
	margin-top:20px;
	left: 0;
	margin-left: 38px;
	width: 40px;
	height: 40px;
	border-left: 1px solid $gray;
	border-bottom: 1px solid $gray;
}
.review-comments-avatar img {
	width:80px;
	height:80px;
	float:left;
	border-radius:100%;
}
.reviews-comments-item-text {
	float:left;
	width:100%;
	position:relative;
	padding:30px 20px;
	background:$gray-7;
	border-radius:6px;
}
.reviews-comments-item-text .new-dashboard-item {
	top:0;
}
.reviews-comments-item-text h4 {
	text-align:left;
	padding-bottom:25px;
	float:left;
	font-size:16px;
	font-weight:600;
	color:$gray-5;
}
.reviews-comments-item-text h4 a {
	color:$gray-5;
}
.reviews-comments-item-text p {
	text-align:left;
}
.reviews-comments-item-date {
	float:left;
	margin-top:18px;
	font-weight:500;
	color:$gray-4;
	width:100%;
	text-align:left;
	padding-top:18px;
	font-size:12px;
	border-top:1px solid $gray;
}
.reviews-comments-item-date span {
	float:left;
}
.reviews-comments-item-date a {
	float:right;
	font-weight:600;
	color:$gray-5;
}
.reviews-comments-item-date span i , .reviews-comments-item-date a i {
	margin-right:10px;
}
.leave-rating , .leave-rating-title {
	float:left;
}
.leave-rating-wrap {
	float:left;
	margin:30px 0 30px 0;
	width:100%;
}
.leave-rating-title {
	margin-right:10px;
	font-weight:500;
	font-size:14px;
	color:$gray-5;
	position:relative;
	top:-3px;
}
.review-score-form  {
	margin-top:10px;
}
.review-total  , .review-range-container{
	float:left;
}
.review-total {
	width:30%;
	background: $gray-light;
	padding:35px 0;
	border:1px solid $gray;
	box-sizing:border-box;
	border-radius:4px;
}
.review-total strong {
	float:left;
	width:100%;
	margin-top:10px;
	color:$gray-5;
}
.custom-form .review-total span input{
	font-size:34px;
	border:none;
	padding:0;
	text-align:center;
	font-weight:600;
	margin-bottom:0;
	line-height:1px;
}
.review-range-container {
	width:70%;
	padding-right:20px;
}
.review-range-item {
	display:block;
	margin-bottom:10px;
}
.review-range-item .range-slider-title  {
	width:30%;
	margin-bottom:0;
}
.review-range-item .range-slider-wrap{
	width:70%;
	margin-top:0;
	position:relative;
	top:-24px;
}
.review-range-item .range-slider-title  , .review-range-item .range-slider-wrap {
	float:left;
}
/*------ Grid Gallery------------------------------------------------*/
.listing-item-cat {
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	padding:30px 35px 15px;
	z-index:10;
	text-align:left;
	overflow:hidden;
	border-radius:0 0 10px 10px;
}
.listing-counter   {
	position:absolute;
	left:35px;
	top:40px;
	color:$white;
	z-index:10;
	font-size:11px;
	border-radius: 4px;
	background: $blue;
	color: $white;
	padding: 9px 12px;
	box-shadow: 0px 0px 0px 5px rgba($white,0.2);
}
.listing-item-cat h3 {
	font-size:18px;
	float:left;
	font-family: 'Montserrat', sans-serif;
	padding-bottom:10px;
}
.listing-item-cat p {
	color:rgba($white,0.71);
}
.listing-item-cat h3 a {
	color:$white;
}
.weather-grid {
	float:left;
	position:relative;
	top:2px;
	margin-left:20px;
}
.gallery-items.mr-bot {
	margin-bottom:40px;
}
.grid-item-holder  , .gallery-items{
	width:100%;
	height:auto;
	position:relative;
	overflow:hidden;
}
.grid-item-holder {
	margin: 10px 0;
	border-radius: 10px;
}
.grid-small-pad  .grid-item-holder{
	padding:0 4px 4px 0;
}
.gallery-item  {
	width: 33.3%;
	position:relative;
}
.listing-item-grid {
	border-radius:6px;
	overflow:hidden;
	position:relative;
}
.gallery-item-second,
.grid-sizer-second {
	width: 66.6%;
}
.four-column  .gallery-item{
	width:25%;
}
.four-column  .gallery-item.gallery-item-second{
	width:50%;
}
.gallery-item img {
	position:relative;
	z-index:1;
}
.list-single-main-item .gallery-items .gallery-item   {
	overflow:hidden;
}
.gallery-item .box-item {
	border-radius:0;
}
.more-photos-button {
	position:absolute;
	bottom:0;
	left:0;
	width:100%;
	height:40px;
	line-height:40px;
	padding:0 60px 0 20px;
	text-align:left;
	color:$white;
	font-size:10px;
	z-index:10;
	text-transform:uppercase;
	cursor:pointer;
}
.more-photos-button span {
	padding:0 2px;
	font-style:italic;
	font-size:9px;
	color:rgba($white,0.61)
}
.more-photos-button i  {
	position:absolute;
	right:0;
	top:0;
	height:40px;
	line-height:40px;
	width:50px;
	border-left: 1px solid rgba($black,0.21);
	text-align:center;
}
/*-------------Ajax modal---------------------------------------*/
.ajax-modal-container {
	position:fixed;
	top:0;
	right:-550px;
	width:550px;
	height:100%;
	z-index:119;
	overflow: hidden;
	overflow-y: auto;
	opacity:0;
	background:$white;
 
}
#ajax-modal {
	overflow:hidden;
	background:$white;
}
.ajax-modal-wrap {
	opacity:0;
}
.ajax-modal-media {
	border-radius:20px 0 0 0;
}
.ajax-modal-media  img{
	width:100%;
	height:auto;
}
.ajax-modal-details {
	padding:30px 45px;
	margin-top:20px;
}
.ajax-modal-details p {
	text-align:left;
}
.ajax-modal-details h3 {
	text-align:left;
	color: $blue-2;
	padding-bottom: 26px;
	font-size: 16px;
	font-weight: 700;
}
.ajax-modal-details-box {
	width:100%;
	float:left;
	margin-bottom:30px;
	padding-bottom:20px;
	border-bottom:1px solid $gray;
}
.ajax-modal-title {
	color: $white;
	padding: 20px 0 25px;
	cursor: pointer;
	font-size: 18px;
	font-weight: 500;
	position: absolute;
	bottom: 0;
	width: 100%;
}
.ajax-modal-photos-btn {
	position:absolute;
	right:30px;
	top:30px;
	z-index:5;
	padding:10px 20px;
	cursor:pointer;
	border-radius:4px;
	color:$white;
	background: $blue;
	font-size:11px;
	box-shadow: 0px 0px 0px 5px rgba($white,0.2);
}
.ajax-modal-photos-btn span {
	padding:0 4px;
}
.ajax-modal-list {
	border-bottom:1px solid $gray;
	background: $gray-7;
}
.ajax-modal-list li {
	float:left;
	width:25%;
	padding:12px 0;
	border-left:1px solid $gray-8;
	box-sizing:border-box;
}
.ajax-modal-list li:first-child {
	border:none;
}
.ajax-modal-list li h5 {
	font-size:11px;
	color:$gray-4;
}
.ajax-modal-list li h5 span{
	color:$gray-5;
	padding-left:4px;
}
.ajax-modal-list li i {
	font-size:28px;
	padding-bottom:10px;
}
.ajax-modal-close{
	position: absolute;
	left:40px;
	width: 40px;
	height: 40px;
	top:25px;
	font-size: 14px;
	color: $white;
	line-height: 40px;
	z-index:20;
	background: $blue;
	border-radius:6px;
	cursor:pointer;
}
.ajax-modal-overlay {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1000;
	overflow: auto;
	display:none;
	z-index:118;
	-webkit-transform: translate3d(0,0,0);
	background:rgba($black,0.41);
}
.ajax-loader {
	z-index:100;
}
/*------ Video ------------------------------------------------*/
.video-box {
	overflow: hidden;
	border-radius: 10px;
	-webkit-transform: translate3d(0,0,0);
	box-shadow: 0px 0px 0px 7px rgba($white,0.2);
}
.video-box img {
	width:100%;
	height:auto;
}
.video-box-btn {
	position: absolute;
	width: 60px;
	height: 60px;
	line-height: 60px;
	margin: -30px 0 0 -30px;
	background: $white;
	color: $white;
	z-index: 20;
	left: 80px;
	bottom:30px;
	box-shadow: 0px 0px 0px 12px rgba($white,0.4);
	border-radius: 100%;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear;
}
.video-box-btn:hover {
	box-shadow: 0px 0px 0px 7px rgba($white,0.4);
}
.video-box-title {
	position:absolute;
	bottom:43px;
	left:140px;
	color:$white;
	font-size:12px;
	z-index:21;
	background: rgba(6,27,65,0.45);
	padding:10px 20px;
	border-radius:4px;
}
.media-container {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
}
.video-container {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	line-height: 0;
	z-index: 1;
}
.video-container video {
	width: 100%;
}
.video-iframe{
	position: absolute;
	width: 100%;
	height: 100%;
	display: block;
	overflow: hidden !important;
	top: 0;
	left: 0;
	padding: 0 !important;
}
.video-iframe iframe  {
	position: absolute;
	top: -75px;
	left: 50%;
	width: 100%;
	height: 100%;
	display: block;
}
.media-container.video-parallax {
	height:120%;
	top:-10%;
}
.resp-video {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px; height: 0; overflow: hidden;
	margin-bottom:20px;
}
.resp-video iframe,
.resp-video object,
.resp-video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video-mask {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1;
}
.video-holder iframe {
	position: absolute;
	top: -75px;
	left: 50%;
	width: 100%;
	height: 100%;
	display: block;
	z-index:1;
}
.mob-bg {
	display:none;
}
/*-------------Sliders---------------------------------------*/
.slideshow-container {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:110%;
	z-index:1;
}
.slideshow-item  , .slideshow-container .slick-slide{
	position:relative;
	float:left;
	width:100%;
	height:100%;
}
.sw-btn{
	position:absolute;
	top:50%;
	width:36px;
	height:36px;
	line-height:36px;
	border-radius:100%;
	color:$white;
	font-size:10px;
	z-index:120;
	cursor:pointer;
	margin-top:-18px;
	box-shadow: 0px 0px 0px 9px rgba($white,0.4);
}
.sw-btn.swiper-button-prev {
	left:50px;
}
.sw-btn.swiper-button-next {
	right:50px;
}
.sw-btn:hover {
	background: $blue-3;
}
.single-carousel .slick-dots , .listing-carousel .slick-dots {
	bottom:-40px;
}
.single-carousel   .slick-dots li button  , .listing-carousel .slick-dots li button {
	background:$gray-14;
}
.list-carousel {
	padding: 0 30px;
}
.list-single-carousel-wrap {
	height:500px;
	background: $blue-4;
}
.fw-carousel .slick-slide-item {
	width:auto;
	height:500px !important;
	float:left;
	cursor: w-resize;
}
.fw-carousel .slick-slide-item img {
	width:auto  ;
	height:100%   !important;
}
.list-single-carousel-wrap    .slick-slide-item .box-item {
	height:100%;
	z-index:20;
}
.list-single-carousel-wrap    .slick-slide-item .box-item:before {
	display:none;
}
.list-single-carousel-wrap    .swiper-slide:first-child {
	padding-left:0px;
}
.list-single-carousel-wrap    .slick-slide-item    .box-item .gal-link {
	top:-100px;
	left:inherit;
	right:50px;
}
.list-single-carousel-wrap    .slick-slide-item   .box-item:hover .gal-link {
	top:50px;
}
.list-single-carousel-wrap .sw-btn , .single-slider-wrapper .sw-btn {
	top:50%;
}
.list-carousel .sw-btn.swiper-button-prev {
	left:8px;
}
.list-carousel .sw-btn.swiper-button-next {
	right:8px;
}
.single-slider-wrapper img , .inline-carousel img {
	width:100%;
	height:auto;
}
.single-slider-wrapper .slider-nav .slick-slide-item  {
	cursor:pointer;
	opacity:0.7;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out
}
.single-slider-wrapper .slider-nav  .slick-current .slick-slide-item , .single-slider-wrapper .slider-nav  .slick-slide-item:hover {
	opacity:1; 
}
.slick-dots {
	position:absolute;
	bottom:40px;
	left:0;
	width:100%;
}
.inline-carousel .slick-dots {
	bottom:20px;
}
.slick-dots li {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 3px;
	padding: 0;
	cursor: pointer;
}
.slick-dots li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 6px;
	height: 6px;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: none;
	background:  rgba($white,0.81);
	border-radius:100%;
}
/*-------------scroll-nav-wrapper---------------------------------------*/
.scroll-nav-container {
	padding-top:0;
}
.scroll-nav-wrapper {
	background:$white;
	z-index:100;
}
.scroll-nav-wrapper.with-border {
	border-bottom:1px solid $gray;
	top: 110px;
}
.scroll-nav-wrapper .scroll-nav li {
	position:relative;
	overflow:hidden;
}.scroll-nav-wrapper .scroll-nav ul {
	display: flex;
	flex-direction: row;
}
.scroll-nav-wrapper .scroll-nav li div {
	padding:0 30px;
	height:100%;
	height:40px;
	line-height:40px;
	position:relative;
	font-weight:700;
	color: $gray-15;
	font-size:12px;
	background: $gray-8;
	cursor: pointer;
}
.scroll-nav-wrapper .scroll-nav li:last-child {
	border-radius:0 4px 4px 0 ;
}
.scroll-nav-wrapper .scroll-nav li:first-child  {
	border-radius:4px 0 0 4px;
}

.scroll-nav-wrapper .show-hidden-map {
	float:right;
	position:relative;
	color: $white;
	border-radius:4px;
	height:40px;
	line-height:40px;
	padding:0 25px;
	font-size:12px;
	font-weight:600;
	background: $blue;
}
.scroll-nav-wrapper .show-hidden-map:hover {
	background: $blue;
}
.scroll-nav-wrapper .show-hidden-map:hover i , .scroll-nav-wrapper .show-hidden-map:hover {
	color:$white;
}
.scroll-nav-wrapper .show-hidden-map i {
	padding-left:10px;
}
.collage-image {
	position:relative;
	width:352px;
	margin:0 auto;
	height:300px;
}
.collage-image .main-collage-image {
	position:absolute;
	right:-20px;
	bottom:-400px;
}
.images-collage-title {
	position:absolute;
	left:-70px;
	width:180px;
	padding:12px 0;
	z-index:10;
	top:70px;
	border-radius:6px;
	font-weight:800;
	font-size:18px;
	color: $white;
	text-transform:uppercase;
	font-family: 'Montserrat', sans-serif;
	box-shadow: 0px 0px 0px 7px rgba($white,0.2);
	animation: bounce 4s ease-in-out infinite;
}
.images-collage-title span {
	color:$white;
}
.collage-image:before {
	content:'';
	position:absolute;
	width:250px;
	height:250px;
	top:50%;
	left:50%;
	margin-top:-125px;
	margin-left:-325px;
	border:2px  dotted $white;
	z-index:-1;
	opacity:0.6;
	border-radius:100%;
}
.collage-image-min  {
	position:absolute;
	z-index:10;
}
.collage-image-min:before {
	top:63px;
	left: 90%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: transparent;
	border-top-color: $white;
	border-width: 14px;
	margin-left: 18px;
	z-index:20;
}
.collage-image-min.cim_1 {
	bottom:70px;
	left:80px;
}
.collage-image-min.cim_2 {
	bottom:190px;
	right:80px;
}
.collage-image-min.cim_2 img  {
	animation:cluster-animation 2.5s infinite;
}
.collage-image-min.cim_1 img {
	animation:cluster-animation 3.5s infinite;
}
.collage-image-min.cim_3 {
	bottom:110px;
	right:130px;
}
.collage-image-min img{
	position:absolute;
	width:67px;
	height:67px;
	border:8px solid $white;
	border-radius:100%;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
}
.collage-image-input {
	position:absolute;
	bottom:-40px;
	right:-90px;
	width:190px;
	padding:0 40px 0 16px;
	background:$white;
	border-radius:6px;
	z-index:10;
	text-align:left;
	font-size:12px;
	color:$gray-4;
	height:40px;
	line-height:40px;
	font-weight:300;
	box-shadow: 0px 0px 0px 7px rgba($black,0.1);
}
.collage-image-btn{
	color:$white;
	position:absolute;
	top:40px;
	right:-20px;
	padding:12px 25px;
	border-radius:4px;
	font-size:10px;
	box-shadow: 0px 0px 0px 7px rgba($black,0.1);
}
.collage-image-input i {
	position:absolute;
	width:30px;
	height:40px;
	line-height:40px;
	right:0;
	top:0;
}
/*------sahre------------------------------------------------*/
.fixed-scroll-column {
	width:50px;
	position:absolute;
	left:15px;
	top:0;
	z-index:20;
}
.share-holder.hid-share   {
	float:left;
	position:relative;
	z-index:20;
}
.fc-button {
	width:50px;
	height:50px;
	float:left;
	position:relative;
	line-height:50px;
	background: $white;
	cursor:pointer;
	border-radius:4px;
	margin-bottom:4px;
}
 
.fc-button span {
	position:absolute;
	left:100%;
	margin-left:12px;
	background: $blue;
	opacity:0;
	visibility: hidden;
	top:8px;
	color:$white;
	width:120px;
	height:auto !important;
	line-height:1;
	font-size:12px;
	padding:12px 0;
	border-radius:22px;
}
.fc-button:hover span {
	opacity:1;
	visibility:visible;
}
.fixed-scroll-column-share-container {
	display:block;
}
.fixed-scroll-column-share-container {
	display:none;
	float:left;
	width:100%;
	background: $gray-7;
	padding:10px 0;
	margin-bottom:4px;
} 
.visshare {
	visibility:visible;
	opacity:1;
}
.share-container  .share-icon {
	display:inline-block;
	width:26px;
	height:26px;
	line-height:26px;
	margin:4px 0;
	position:relative;
	font-size:13px;
	color: $gray;
	font-weight:400;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out
}
.fixed-scroll-column-share-container .share-container  .share-container  .share-icon{
	float:left;
	width:50px;
	height:50px;
	color:$gray-2;
} 
.share-holder.hid-share .share-container.visshare , .bl-opt  .share-holder.hid-share .share-container.visshare{
	top:-35px;
	opacity:1;
	visibility:visible;
}
.share-icon:before {
	font-family: Font Awesome\ 5 Brands;
}
.share-icon-digg:before {
	content: "\f1a6";
}
.share-icon-email:before  {
	content: "\f003";
}
.share-icon-flickr:before  {
	content: "\f16e";
}
.share-icon-foursquare:before  {
	content: "\f180";
}
.share-icon-rss:before {
	content: "\f09e";
}
.share-icon-facebook:before  {
	content: "\f39e";
}
.share-icon-twitter:before {
	content: "\f099";
}
.share-icon-tumblr:before {
	content: "\f173";
}
.share-icon-linkedin:before {
	content: "\f0e1";
}
.share-icon-vimeo:before {
	content: "\f27d";
}
.share-icon-instagram:before {
	content: "\f16d";
}
.share-icon-pinterest:before {
	content: "\f231";
}
.share-icon-googleplus:before {
	content: "\f0d5";
}
.share-icon-in1:before {
	content: "\f08c";
}
.share-icon-stumbleupon:before {
	content: "\f1a4";
}
/* --------- clients - terstimonials--------------------------------------*/
.text-carousel .text-carousel-item {
	padding: 30px 65px;
	background: $white;
	border: 1px solid $gray;
	border-radius: 4px;
	margin: 0 10px;
	float: left;
	width: 100%;
	position: relative;
	margin-top: 70px;
	-webkit-transition: all 400ms linear;
	transition: all 400ms linear;
}
.text-carousel .text-carousel-item:before,
.text-carousel .text-carousel-item:after {
	font-family: Font Awesome\ 5 Pro;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	position: absolute;
	color: $gray-2;
	opacity: 0.4;
	font-size: 34px;
	-webkit-transition: all 400ms linear;
	transition: all 400ms linear;
}
.text-carousel-item:before,
.pr-list-det:before {
	content: "\f10e";
	top: 20px;
	left: 15px;
}
.text-carousel-item:after,
.pr-list-det:after {
	content: "\f10e";
	bottom: 20px;
	right: 15px;
}
.text-carousel .slick-item {
	padding: 0 20px;
}
.text-carousel .slick-current  .text-carousel-item {
	background: $gray-8;
	border-color:transparent;
}
.text-carousel .slick-dots {
	margin-top: 40px;
	bottom:-50px;
}
.text-carousel .slick-dots li {
	display: inline-block;
	margin: 0 3px;
}
.text-carousel .slick-dots li button:before {
	background: $gray-2;
}
.text-carousel .slick-dots li.slick-active button  {
	border-color: $gray-2;
}
.text-carousel-wrap {
	margin: 0 0 50px 0;
}
.text-carousel-item p {
	font-size: 13px;
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style: italic;
	text-align: left;
}
.text-carousel-item a.testim-link {
	float: left;
	margin-top: 20px;
	font-weight: 400;
	font-size: 12px;
	position: relative;
	color:$gray-5;
}
.text-carousel-item a.testim-link:before {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 0;
	width: 100%;
	height: 1px;
	border-bottom: 1px dotted $gray-2;
}
.text-carousel-item .listing-rating {
	float: right;
	width: 100%;
	margin-bottom: 12px;
}
.text-carousel-item .listing-rating i {
	float: right;
	margin-left: 10px;
	font-size: 11px;
}
.popup-avatar {
	position: absolute;
	left: 70px;
	z-index: 10;
	width: 80px;
	height: 80px;
	z-index: 20;
	border-radius: 100%;
	-webkit-transition: all 400ms linear;
	transition: all 400ms linear;
	opacity: 0.5;
	top: -40px;
	-webkit-transform: scale(0.7);
	-moz-transform:scale(0.7);
	transform:scale(0.7);
}
.text-carousel .slick-current  .popup-avatar {
	opacity: 1;
	-webkit-transform: scale(1.0);
	-moz-transform:scale(1.0);
	transform:scale(1.0);
}
.popup-avatar:before {
	content: '';
	position: absolute;
	border-radius: 100%;
	left: -8px;
	bottom: -8px;
	right: -8px;
	top: -8px;
	border: 1px solid $gray;
	z-index: 1;
	background: $white;
}
.popup-avatar img {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	position: relative;
	z-index: 2;
}
.review-owner {
	margin: 0 0 15px 0;
	float: left;
	margin-top: 20px;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 11px;
	text-align: left;
	color: $gray-5;
	font-family: 'Poppins', sans-serif;
}
.review-owner span {
	color: $gray-4;
}
.text-carousel-wrap .sw-btn{
	top:100%;
	margin-top:26px;
}
.text-carousel-wrap .sw-btn.swiper-button-prev {
	left:50%;
	margin-left:-105px;
}
.text-carousel-wrap .sw-btn.swiper-button-next {
	right:50%;
	margin-right:-100px;
}
/*------Dasboard------------------------------------------------*/
.dasboard-wrap {
	padding-left:290px;
}
.dasboard-sidebar {
	position:absolute;
	left:0;
	width:250px;
	z-index:20;
	top:-50px;
}
.dasboard-sidebar-content {
	padding:40px 0 44px 0;
	border-radius:6px;
	z-index:20;
	overflow:hidden;
}
.log-out-btn {
	position:absolute;
	width:100%;
	left:0;
	height:44px;
	bottom:0;
	line-height:44px;
	color:$white;
	font-size:10px;
	text-transform:uppercase;
	text-align: center;
}
.log-out-btn i {
	padding-left:10px;
}
.dasboard-avatar {
	overflow:hidden;
	padding:0 55px;
	position:relative;
}
.dasboard-avatar img {
	width:100%;
	height:auto;
	border-radius:4px;
}
.dasboard-sidebar-item {
	margin:30px 0 20px;
}
.dasboard-sidebar-item:before {
	content:'';
	position:absolute;
	left:50%;
	width:30px;
	bottom:-10px;
	height:2px;
	margin-left:-15px;
	border-radius:2px;
	background:rgba($white,0.21);
}
.dasboard-sidebar-item h3 {
	color:$white;
	font-size:18px;
}
.dasboard-sidebar-item h3 span {
	float:left;
	width:100%;
	padding-bottom:10px;
	font-size:11px;
}
.ed-btn {
	display:inline-block;
	padding:8px 17px;
	margin:10px 0;
	color:$white;
	font-size:11px;
	border-radius:30px;
	z-index:2;
	border:2px solid;
	background:rgba($white,0.11);
}
.dasboard-menu ,  .dasboard-menu li{
	float:left;
	position:relative;
}
.dasboard-menu {
	top:2px;
}
.flat-header {
	padding-top:140px;
	padding-bottom:0;
}
.adm-header {
	z-index:100;
}
.dasboard-menu-btn {
	float:left;
	padding:14px 20px;
	color:$white;
	cursor:pointer;
	width:100%;
	border-radius:6px 6px 0 0;
	display:none;
}
.dasboard-menu-btn i {
	padding-left:10px;
}
.dasboard-menu li ul {
	position:absolute;
	left:0;
	top:100%;
	background:$white;
	padding:10px 20px;
	width:150px;
	z-index:2;
	border:1px solid $gray;
	border-top:none;
	opacity:0;
	visibility:hidden;
}
.dasboard-menu li:hover ul {
	opacity:1;
	visibility:visible;
}
.dasboard-menu li ul li {
	float:left;
	width:100%;
	padding:8px 0;
	position:relative;
}
.dasboard-menu li ul li span {
	position:absolute;
	top:50%;
	right:0;
	width:16px;
	height:16px;
	margin-top:-8px;
	line-height:16px;
	border-radius:100%;
	font-size:9px;
	color:$white;
}
.dasboard-menu li a {
	padding:13px 20px;
	float:left;
	font-size:12px;
	border-radius:4px 4px 0 0;
	color:$white;
	position:relative;
}
.dasboard-menu li ul li a {
	padding:0;
	color:$gray-5 !important;
	border-radius:0;
}
.dasboard-menu li a i {
	padding-right:6px;
}
.dasboard-menu li a span {
	display: inline-block;
	width: 16px;
	height: 16px;
	top: -1px;
	line-height: 16px;
	background: $gray-10;
	color: $white;
	position:relative;
	margin-left:4px;
	border-radius: 100%;
	text-align: center;
	font-weight: 300px;
	font-size: 9px;
}
.dasboard-menu li a.user-profile-act   , .dasboard-menu li a:hover {
	background:$white !important;
	color:$gray-5 !important;
	box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.07);
}
.dasboard-menu li ul li a:hover {
	box-shadow:none;
} 
.user-stats {
	margin-top:10px;
	border-top: 1px solid rgba($black,0.21);
}
.user-stats:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -2;
	border-radius: 6px;
	opacity: 0.14;
}
.user-stats li {
	float:left;
	width:33.3%;
	padding:15px 0;
	color:$white;
	font-size:11px;
	box-sizing:border-box;
	border-left: 1px solid rgba($black,0.21);
}
.user-stats li:first-child {
	border:none;
}
.user-stats li span {
	float:left;
	width:100%;
	padding-top:10px;
	font-size:14px;
}
.tfp-btn {
	position:absolute;
	right:0;
	top:-110px;
	background:$white;
	padding:12px 40px 12px 30px;
	border-radius:4px;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
	z-index:10;
	color:$gray-5;
	font-size:12px;
	cursor:pointer;
}
.tfp-btn strong {
	padding-left:5px;
}
.tfp-btn:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f0d7";
	position: absolute;
	color: $white;
	top:50%;
	font-size: 13px;
	right: 11px;
	margin-top:-7px;
	font-weight: 800;
	transition: all .2s ease-in-out;
}
.tfp-btn.rot_tfp-btn:before {
	-webkit-transform: rotateX(180deg);
	transform: rotateX(180deg);
}
.tfp-det {
	position:absolute;
	top:-70px;
	right:0;
	z-index:11;
	padding:15px 30px;
	background:$white;
	border:1px solid $gray;
	border-radius:4px;
	opacity:0;
	visibility:hidden;
	margin-top:10px;
	transition: all .2s ease-in-out;
}
.tfp-det-btn {
	float:right;
	padding:6px 12px;
	color:$white;
	font-size:11px;
	border-radius:4px;
	margin-top:3px;
}
.tfp-det.vis_tfp-det {
	opacity:1;
	visibility: visible;
	margin-top:0;
}
.dasboard-breadcrumbs {
	position:absolute;
	top:-100px;
	left:0;
	z-index:1;
}
#myChartLegend {
	float:right;
}
.chart-header {
	margin-bottom:20px;
}
.chart-header .listsearch-input-item {
	width:150px;
}
[class="0-legend"] {
	cursor: pointer;
	list-style: none;
	padding-left: 0;
	float:right;
}
[class="0-legend"] li {
	float: left;
	padding: 14px 25px;
	border-radius: 2px;
	color: $gray;
	font-size: 12px;
	background: $gray-8;
	font-weight: 500;
	margin-left:10px;
}
[class="0-legend"] li.hidden-lable {
	text-decoration: line-through;
}
[class="0-legend"] li span {
	border-radius: 5px;
	display: inline-block !important;
	height: 10px;
	margin-right: 10px;
	width: 10px;
}
.dashboard-list-box {
	border:1px solid $gray;
	border-radius:10px;
	overflow:hidden;
}
.mat-top{
	margin-top:50px;
}
.dashboard-header {
	padding:20px 30px;
	border-bottom:1px solid $gray;
}
.dashboard-header h3 {
	font-size:16px;
	font-weight:600;
	text-align:left;
	color:$white;
}
.dashboard-list {
	float:left;
	padding:25px 30px;
	position:relative;
	border-bottom:1px solid $gray;
	width:100%;
}
.dashboard-list:nth-child(2n+1) {
	background: $gray-7;
}
.dashboard-list:last-child {
	border:none;
}
.dashboard-message-avatar {
	float:left;
	width:50px;
	height:50px;
}
.dashboard-message-text .btn {
	margin-top:10px;
	float:left;
	background: $green-light !important;
	padding:12px 20px;
	font-weight:500;
	font-size:12px;
}
.dashboard-message-text .btn:hover{
	color:rgba($white,0.71)
}
.dashboard-message-avatar img {
	width:50px;
	height:50px;
	border-radius:100%;
}
.dashboard-message-text {
	margin-left:30px;
	text-align:left;
	padding-left:50px;
	top:6px;
	position:relative;
}
.dashboard-message-text h4  , .dashboard-listing-table-text h4{
	font-weight:600;
	font-size:16px;
	padding-bottom:20px;
	color:$gray-3;
}
.dashboard-message-text h4 span {
	font-weight:500;
	font-size:12px;
}
.reply-mail {
	text-align:left;
	font-weight:500;
}
.reply-mail a {
	font-weight:400;
}
.reply-mail a:hover {
	color:$gray-4;
}
.new-dashboard-item{
	position:absolute;
	right:30px;
	top:28px;
	padding:6px 15px;
	border-radius:4px;
	color:$white;
	background:$green-light;
	z-index:10;
}
.dashboard-listing-table-image {
	float:left;
	width:30%;
	padding:0 30px 0 0;
}
.dashboard-listing-table-image img {
	width:100%;
	height:auto;
	border-radius:6px;
}
.dashboard-listing-table-text {
	float:left;
	text-align:left;
	width:70%;
}
.dashboard-listing-table-text h4 a {
	color:$gray-3;
}
.dashboard-listing-table-address {
	float:left;
	padding-bottom:10px;
}
.dashboard-listing-table-address a {
	font-weight:600;
	color:$gray-4;
	font-size:10px;
	text-transform:uppercase;
}
.dashboard-listing-table-address i {
	padding-right:10px;
}
.dashboard-listing-table-opt li {
	float:left;
	margin-right:10px;
}
.dashboard-listing-table-opt {
	margin-top:15px;
}
.dashboard-listing-table-opt li  a {
	color:$white;
	padding:9px 22px;
	border-radius:4px;
	background: $green-light;
	float:left;
	font-size:12px;
}
.dashboard-listing-table-opt li  a i {
	padding-left:10px;
}
.dashboard-listing-table-opt li  a.del-btn:hover {
	background:$red;
}
.profile-edit-header h4 span{
	color: $green-light;
}
.notification {
	padding:18px 20px;
	border-radius:4px;
	margin-bottom:40px;
}
.notification p {
	color:$white;
	text-align:left;
	padding-bottom:0;
}
.notification p a {
	color:  rgba($white,0.71);
	font-weight:600;
	padding:0 5px;
}
.notification.success {
	background: $green-light;
}
.notification.waitforreview{
	background:$yellow;
}
.notification.reject{
	background:$red;
}
.notification-close {
	position:absolute;
	top:50%;
	right:20px;
	height:20px;
	margin-top:-10px;
	color:rgba($white,0.71);
}
.notification-close:hover {
	color:$white;
}
.dashboard-list-box.activities .dashboard-list {
	padding-left:0;
}
.dashboard-list-box.activities  .dashboard-message-text {
	padding-left:0;
}
.dashboard-list-box.activities  .dashboard-message-text i {
	padding-right:20px;
}
.dashboard-list-box.activities .new-dashboard-item {
	cursor:pointer;
}
.profile-edit-container {
	margin:10px 0;
	float:left;
	width:100%;
}
.profile-edit-container .custom-form label {
	float:left;
	text-align:left;
	margin-bottom:10px;
	font-weight:500;
}
.profile-edit-container .custom-form label i {
	top:42px;
}
.custom-form.no-icons input , .custom-form.no-icons textarea {
	padding-left:10px;
}
.pass-input-wrap span {
	position:absolute;
	right:20px;
	cursor:pointer;
	bottom:36px;
	z-index:10;
}
.profile-edit-container.add-list-container {
	margin-top:30px;
	padding-bottom:30px;
	border-bottom:1px solid $gray;
	float:left;
	width:100%;
}
.profile-edit-container.add-list-container:first-child {
	margin-top:0;
}
.add-list-media-header {
	float:left;
	width:100%;
	padding:15px 20px 3px;
	background: $gray-light;
	border:1px solid $gray;
}
.add-list-media-header span {
	text-align:left;
	float:left;
}
.booking-details {
	padding:6px 0;
	font-size:12px;
}
.booking-details:last-child {
	padding-bottom:12px;
	margin-bottom:10px;
	border-bottom:1px solid $gray;
}
.booking-title {
	color:$gray-4;
	padding-right:10px;
}
.booking-text  , .booking-text a{
	color:$gray-5;
}
.done-paid {
	color:$green-light;
	text-decoration:underline;
}
.chat-box {
	padding:50px 30px 60px;
	min-height:300px;
}
.chat-message {
	padding-left:70px;
	margin-bottom:30px;
}
.chat-message:last-child {
	margin-bottom:0;
}
.chat-message p{
	float:left;
	padding:15px 20px;
	background:$white;
	border-radius:10px;
	width:100%;
	text-align:left;
	position:relative;
}
.chat-message p:before{
	font-family: Font Awesome\ 5 Pro;
	content: "\f0d9";
	position: absolute;
	top: 12px;
	left: -9px;
	font-size: 31px;
	color:$white;
	font-weight:bold;
}
.chat-message.chat-message_user p:before {
	content: "\f0da";
	left:inherit;
	right:-9px;
}
.massage-date {
	float:left;
	font-size:11px;
	color:$gray-4;
	margin-bottom:11px;
}
.massage-date span{
	position:relative;
	padding-left:10px;
}
.massage-date span:before {
	content:'';
	position:absolute;
	left:2px;
	width:4px;
	height:4px;
	top:4px;
	z-index:5;
	border-radius:100%;
}
.chat-message.chat-message_user .massage-date {
	float:right;
}
.chat-message .dashboard-message-avatar{
	position:absolute;
	left:0;
	top:20px;
}
.chat-message.chat-message_user  .dashboard-message-avatar {
	left:inherit;
	right:0;
}
.chat-message .dashboard-message-avatar span {
	font-size:11px;
	color:$gray-5;
	display:inline-block;
	margin-top:6px;
}
.chat-message.chat-message_user {
	padding-left:0;
	padding-right:70px;
}
.message-input {
	float:left;
	width:100%;
	position:relative;
	border-right:1px solid $gray;
}
.message-input textarea{
	position:relative;
	width:100%;
	border:none;
	background:$white;
	z-index:1;
	resize: vertical;
	min-height:60px;
	padding:25px 140px 0 30px;
}
.message-input button {
	position:absolute;
	right:20px;
	border-radius:36px;
	width:100px;
	height:36px;
	line-height:36px;
	background:none;
	border:none;
	font-size:14px;
	top:12px;
	z-index:2;
	color:$white;
	cursor:pointer;
}
.chat-contacts-item {
	float:left;
	width:100%;
	position:relative;
	padding:20px  20px 20px 70px;
	border-bottom:1px solid $gray;
	box-sizing:border-box;
}
.chat-contacts-item .dashboard-message-avatar{
	position:absolute;
	top:15px;
	left:0;
}
.chat-contacts-item .chat-contacts-item-text p {
	height: 26px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-size: 12px;
	color: $gray-6;
}
.chat-contacts-item .chat-contacts-item-text h4 {
	text-align:left;
	font-size:12px;
	color:$gray-5;
	padding-bottom:4px;
}
.chat-contacts-item .chat-contacts-item-text span {
	position:absolute;
	right:20px;
	top:21px;
	font-size:9px;
	color:$gray-4;
}
.message-counter {
	position:absolute;
	left:0;
	bottom:-2px;
	z-index:10;
	width:20px;
	height:20px;
	line-height:20px;
	font-size:11px;
	color:$white;
	border-radius:100%;
}
.nopadding {
	padding: 0 !important;
	margin: 0 !important;
}
.chat-contacts-item:after {
	content:'';
	position:absolute;
	right:0;
	width:6px;
	height:0;
	z-index:-1;
	top:0;
	transition: all .2s ease-in-out;
}
.chat-contacts-item_active:before {
	content:'';
	position:absolute;
	right:0;
	top:0;
	bottom:0;
	left:-30px;
	background:$gray-8;
	z-index:-2;
}
.chat-contacts-item.chat-contacts-item_active:after , .chat-contacts-item:hover:after {
	height:100%;
}
.dashboard-list-box .reviews-comments-wrap {
	padding:0 30px;
}
.add-button{
	float:left;
	padding:10px 15px;
	border-radius:4px;
	color:$white;
	font-size:11px;
	cursor:pointer;
 
}
.hotel-facts  input{
	width:30% !important;
	padding-left:10px !important;
	margin-right:1%;
}
.act-widget-header {
	float: left;
	width: 100%;
	position: relative;
	border-radius: 6px;
	background: $gray-light;
	padding: 10px 20px;
	border: 1px solid $gray;
	margin-bottom: 15px;
}
.act-widget h4 {
	float: left;
	position: relative;
	font-size: 12px;
	font-weight: 400;
	top: 16px;
	color:$gray-4;
}
.act-widget-header .onoffswitch {
	float: right;
	top: 6px;
}
.error_message , #success_page h3 {
	text-align:left;
	margin-bottom:10px;
	font-size:12px;
	font-weight:800;
	text-transform:uppercase;
}
#success_page p {
	margin-top:10px;
}

.booking-modal-wrap  .success-table-header a {
	float:left;
	top:0;
}
.booking-modal-wrap  .success-table-header p {
	width:100%;
}
.booking-modal-wrap  , .bmw-overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	overflow: hidden;
	overflow-Y:auto;
	-webkit-transform: translate3d(0,0,0);
	display:none;
}
.bmw-overlay {
	z-index:999;
	background: rgba($black,0.81);
}
.booking-modal-container {
	max-width:1000px;
	margin:120px auto 70px;
	position:relative;
}
.booking-modal-content {
	background:$white;
	border-radius:10px;
	overflow:hidden;
	box-shadow: 0px 0px 0px 10px rgba($white,0.3);
	margin-bottom: 50px;
	padding-right:300px;
}
.booking-modal-info {
	position:absolute;
	right:0;
	top:0;
	height:100%;
	width:300px;
	background:$gray;
	padding:100px 30px 20px;
}
.booking-modal-info .overlay {
	opacity:0.3;
}
.booking-modal-info_content {
	z-index:20;
}
.booking-modal-info_content h4{
	text-align:center;
	font-size:18px;
	color:$white;
	font-weight:800;
	padding-bottom:15px;
	margin-bottom:15px;
	position:relative;
}
.booking-modal-info_content h4:before {
	content:'';
	position:absolute;
	bottom:0;
	left:50%;
	width:50px;
	height:4px;
	margin-left:-25px;
	border-radius:4px;
	background:$color-secondary-gradient;
}
.booking-modal-info_content ul li {
	float:left;
	width:100%;
	padding:6px 0;
	color:$white;
	text-align:center;
	font-weight:600;
}
.booking-modal-info_content ul li span {
	opacity:0.7;
}
.booking-modal-close{
	position: absolute;
	top: 14px;
	right: 20px;
	border-radius: 100%;
	color: $white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	transition: all .2s ease-in-out;
	z-index:20;
}
.booking-modal-wrap .list-single-main-item {
	padding: 10px 20px;
	background: $white;
	border-radius: 0;
	border: none;
	margin-bottom: 0;
}
.booking-modal-wrap .soc-log  {
	padding:15px 0;
}
.bookiing-form-wrap {
	padding:20px 30px;
}
/*------ to-top------------------------------------------------*/
.to-top {
	position:fixed;
	bottom:120px;
	right:20px;
	width:40px;
	height:40px;
	color:$white;
	line-height:40px;
	font-size:17px;
	z-index:116;
	cursor:pointer;
	display:none;
	border-radius:3px;
	box-shadow: 0px 0px 0px 4px rgba($black,0.2);
	-webkit-transform: translate3d(0,0,0);
}
.to-top:hover {
	background: $blue;
	color:$white;
}
/*------FAQ------------------------------------------------*/
.faq-nav {
	margin-bottom:30px;
}
.faq-nav li {
	text-align:left;
	width:100%;
	float:left;
	border-bottom:1px solid $gray;
}
.faq-nav li a {
	color:$gray-5;
	float:left;
	width:100%;
	padding:14px 12px;
	position:relative;
	font-size:12px;
}
.faq-nav li a:hover {
	color:$gray-4;
}
.faq-nav li a:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f105";
	position: absolute;
	top: 12px;
	right: 10px;
	font-size: 12px;
	color:$gray-2;
}
.process-item {
	padding:20px 30px;
	background:$white;
	border-radius:4px;
	position:relative;
	border:1px solid $gray;
}

.process-item.nodecpre:before {
	display:none;
}
.big-pad-pr-item {
	padding:30px 30px;
}
.process-item h4 {
	font-size: 16px;
	font-weight: 600;
	padding:10px 0;
}
.process-item h4  , .process-item h4 a{
	color: $blue;
}
/*------404------------------------------------------------*/
.error-wrap h2{
	font-size:134px;
	color:$white;
	font-weight:900;
	letter-spacing:10px;
}
.error-wrap p {
	color:rgba($white,0.91);
	font-size:14px;
}
.error-wrap form {
	margin:30px auto 20px;
	max-width:550px;
	overflow:hidden;
	border-radius:6px;
	position:relative;
	box-shadow: 0px 0px 0px 7px rgba($white,0.2);
}
.error-wrap form input {
	float: left;
	width: 100%;
	border: none;
	background: $white;
	height: 50px;
	padding: 0 100px 0 20px;
	z-index: 1;
}
.error-wrap form  .search-submit {
	position: absolute;
	top: 0;
	right: 0;
	width: 100px;
	height: 50px;
	line-height: 50px;
	z-index: 2;
	color: $white;
	border: none;
	cursor:pointer;
}
.error-wrap a.btn {
	display:inline-table;
	margin-top:10px;
}
.city-bg {
	height:325px;
	top:inherit;
	bottom:0;
	opacity:0.8;
	background:url(../../images/city.png) repeat-x;
}
.wave-bg.wave-bg2 {
	background:url(../../images/pattern.png) repeat;
	opacity:0.1;
}
.cloud-anim {
	z-index:1;
	font-size:54px;
	opacity:0.8;
	color:$white;
	position:absolute;
}
.cloud-anim-bottom {
	bottom:350px;
	right:150px;
	-webkit-animation: animateCloud2 20s linear infinite;
	animation: animateCloud2 20s linear infinite;
}
.cloud-anim-top {
	top:120px;
	left:150px;
	-webkit-animation: animateCloud 35s linear infinite;
	animation: animateCloud 35s linear infinite;
}
@keyframes animateCloud {
	0% {
		left: -110px;
	}
	100% {
		left: 100%;
	}
}
@keyframes animateCloud2 {
	0% {
		right: -110px;
	}
	100% {
		right: 100%;
	}
}
@keyframes animateCloud3 {
	0% {
		left: 20px;
	}
	50% {
		left: 90%;
	}
	100% {
		left: 20px
	} 
}
@keyframes animateCloud4 {
	0% {
		right: 50px;
	}
	50% {
		right: 80%;
	}
	100% {
		right: 50px;
	} 
}
@keyframes animateStars {
	0% {
		bottom: -10px;
		opacity:0.5
	}
	50% {
		bottom: 80%;
		opacity:0.2
	}
	100% {
		bottom: -10px;
		opacity:0.5
	} 
}
@keyframes animateStars2 {
	0% {
		top: -10px;
		opacity:0.5
	}
	50% {
		top: 80%;
		opacity:0.2
	}
	100% {
		top: -10px;
		opacity:0.5
	} 
}
/*------ price tables------------------------------------------------*/
.price-item {
	float:left;
	width:33.3%;
	position:relative;
	z-index:1;
}
.price-item:hover , .best-price {
	box-shadow: 0px 0px 90px 0px rgba(0, 0, 0, 0.07);
	z-index:2;
}
.best-price {
	top:-30px;
	transform: scale(1.05);
}
.price-item:hover {
	z-index:3;
}
.price-head {
	float:left;
	width:100%;
	padding:22px 20px;
	position:relative;
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	overflow:hidden;
}
.price-head:before , .price-head:after {
	content: '';
	position: absolute;
	bottom: -150px;
	right: -60px;
	width: 200px;
	height: 200px;
	background: $white;
	border-radius: 100%;
	opacity: 0.2;
}
.price-head:after{
	bottom: 20px;
	right: inherit;
	left: -200px;
	width: 300px;
	height: 300px;
}
.price-head-decor {
	position:relative;
	width:100%;
	display:inline-table;
	margin-bottom:20px;
	padding:20px 0;
}
.price-head-decor i {
	font-size:74px;
	color:$white;
	-webkit-box-reflect: below 4px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(250, 250, 250, 0.2)));
}
.price-item:hover  .price-head-decor i{
	animation: bounce 2s ease-in-out infinite;
}
.price-item:hover .price-head-decor:before {
	-webkit-animation: animateCloud3 12s ease-in-out infinite;
	animation: animateCloud3 12s ease-in-out infinite;
}
.price-item:hover .price-head-decor:after {
	-webkit-animation: animateCloud4 15s ease-in-out infinite;
	animation: animateCloud4 15s ease-in-out infinite;
}
.price-head-decor:before , .price-head-decor:after {
	font-family: Font Awesome\ 5 Pro;
	content: "\f0c2";
	position:absolute;
	color:$white;
	font-size:25px;
	font-weight:500;
	z-index:120;
	opacity:0.5;
}
.price-head-decor.stars-dec:before , .price-head-decor.stars-dec:after{
	content: "\f005";
}
.price-head-decor.cloud-2:before , .price-head-decor.cloud-2:after{
	font-weight:800;
}
.price-item:hover .price-head-decor.stars-dec:before {
	-webkit-animation: animateStars 3s ease-in-out infinite;
	animation: animateStars 3s ease-in-out infinite;
}
.price-item:hover .price-head-decor.stars-dec:after {
	-webkit-animation: animateStars2 5s ease-in-out infinite;
	animation: animateStars2 5s ease-in-out infinite;
}
.price-head-decor:before {
	left:20px;
	bottom:-10px;
}
.price-head-decor:after {
	top:-10px;
	right:50px;
}
.best-price .price-head , .price-num.col-dec-2:before{
	background: $green-light;
}
.price-head h3 {
	color:$white;
	position:relative;
	z-index:2;
	padding-bottom:10px;
	margin-bottom:10px;
	font-size:18px;
	font-weight:500;
	display:inline-table;
	border-bottom:1px   dotted rgba($white,0.81);
}
.price-num {
	padding:20px 15px 0;
	overflow:hidden;
}
.price-num:before {
	content:'';
	position:absolute;
	left:50%;
	top:-35px;
	width:50px;
	height:50px;
	margin-left:-25px;
	border-radius:100%;
	z-index:10;
	-webkit-transform: translate3d(0,0,0);
}
 
.price-num span.curen {
	position:relative;
	top:-32px;
	padding-right:7px;
	font-weight:400;
	font-size:17px;
}
.price-num-desc {
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style:italic;
	color: $gray-4;
	display: inline-table;
	font-size:12px;
	margin-top:6px;
	font-weight:300;
	position:relative;
	overflow:hidden;
}
.mouth-cont {
	z-index:1;
	opacity:1;
	position:relative;
}
.year-cont {
	position:absolute;
	left:0;
	opacity:0;
	top:0;
	z-index:2;
}
.year-cont , .year-sale  , .price-item{
	-webkit-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
}
.year-sale {
	position:absolute;
	top:60px;
	right:30px;
	font-size:36px;
	font-weight:300;
	color:rgba($white,0.41);
	opacity:0;
}
.year-mont .year-sale {
	opacity:1;
	top:80px;
}
.year-mont .mouth-cont {
	opacity:0;
}
.year-mont .year-cont {
 
	opacity:1;
}
.year-mont .price-num-item  .year-cont {
	left:-30px;
}
.price-num-item {
	font-size:55px;
	position:relative;
	font-weight:600;
	color:$gray-5;
	display:inline-table;
}
.price-desc{
	padding:20px 0 30px;
}
.price-desc ul li {
	float:left;
	width:100%;
	padding:18px 0;
	color:$gray-5;
	font-weight:500;
	font-size:12px;
	border-bottom:1px solid $gray;
}
.price-desc ul li:nth-child(2n+1) {
	background: $gray-7;
}
.price-desc ul li:first-child {
	border-top:1px solid $gray;
}
.price-link {
	display:inline-table;
	padding:15px 20px;
	color:$white;
	border-radius:6px;
	font-size:12px;
	margin-top:30px;
	font-weight:400;
}
.price-content {
	background:$white;
	border:1px solid $gray;
	border-top:none;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
}
.recomm-price {
	margin-top:40px;
	font-weight:400;
	color:$gray-4;
	font-size:12px;
}
.recomm-price i {
	width:40px;
	height:40px;
	line-height:40px;
	color:$white;
	font-size:16px;
	border-radius:100%;
	margin-bottom:10px;
}
.pricing-wrap {
	margin:60px 0 30px;
} 
.pricing-switcher {
	text-align: center;
	position:relative;
}
.pricing-switcher:before {
	content:'';
	position:absolute;
	left:50%;
	width:1px;
	height:80px;
	z-index:-1;
	bottom:-53px;
	background:$gray;
}
.pricing-switcher .fieldset {
	display: inline-block;
	position: relative;
	padding: 2px;
	border-radius:30px;
	margin-bottom:30px;
}
.pricing-switcher input[type="radio"] {
	position: absolute;
	opacity: 0;
	left:-1000px;
}
.pricing-switcher label {
	position: relative;
	z-index: 1;
	display: inline-block;
	float: left;
	width: 110px;
	height: 36px;
	line-height: 36px;
	cursor: pointer;
	font-weight:600;
	font-size:11px;
	color:$white;
	-webkit-transition: all 400ms ease-out;
	transition: all 400ms ease-out;
}
.pricing-switcher .switch {
	position: absolute;
	top: 5px;
	left: 5px;
	height: 30px;
	width: 110px;
	background: $white;
	border-radius: 30px;
	-webkit-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
}
.pricing-switcher input[type="radio"]:checked + label + .switch,
.pricing-switcher input[type="radio"]:checked + label:nth-of-type(n) + .switch {
	-webkit-transform: translateX(104px);
	transform: translateX(104px);
}
.pricing-switcher input[type="radio"]:checked + label {
	color:$gray-4;
}
/*------Blog------------------------------------------------*/
.article-masonry {
	width:50%;
	float:left;
	padding:0 10px;
	margin-bottom:20px;
}
.card-post  {
	float:left;
	width:100%;
	position:relative;
	overflow:hidden;
	border-radius:6px;
	background:$white;
}
.card-post .btn {
	margin-bottom:20px;
}
.post-article  {
	margin-bottom:30px;
	float:left;
	width:100%;
	position:relative;
}
.post-article .list-single-main-media  , .card-post .list-single-main-media {
	margin:0;
}
.post-article-title {
	padding:30px;
}
.card-post-img  img{
	float:left;
	width:100%;
	height:auto;
}
.card-post-content {
	padding:15px 20px 0;
	border:1px solid $gray;
}
.card-post-content h3 {
	float:left;
	width:100%;
	text-align:left;
	color:$gray-5;
	font-size:16px;
	font-weight:700;
	padding:15px 0;
	border-bottom:1px solid $gray;
	margin-bottom:15px;
}
.card-post-content h3:before {
	font-family: Font Awesome\ 5 Pro;
	content: "\f0d7";
	position: absolute;
	right: 20px;
	top: 30px;
}
.card-post-content h3 a {
	color: $gray-5;
}
.card-post-content p {
	text-align:left;
}
.home-posts {
	margin-bottom:40px;
}
.card-post-content .post-opt  {
	border-top:1px solid $gray;
	width:100%;
	padding:15px 0;
	margin:20px 0 5px;
}
.post-nav {
	margin-top:20px;
	margin-bottom:10px;
}
.post-nav:before {
	content:'';
	position:absolute;
	left:50%;
	height:50px;
	top:50%;
	margin-top:-25px;
	width:1px;
	background:$gray;
}
.post-link {
	position:relative;
	font-size:15px;
	font-weight:400;
	color: $gray-4;
}
.post-link span {
	color: $gray-4;
	font-size:12px;
	font-weight:600;
}
.post-link i {
	width:40px;
	height:40px;
	position:absolute;
	top:0;
	line-height:40px;
	font-size:22px;
}
.post-link:hover i  {
	-webkit-transform: rotateX(360deg);
	transform: rotateX(360deg);
}
.post-link.prev-post-link {
	float:left;
	padding-left:40px;
	text-align:left;
}
.post-link.next-post-link {
	float:right;
	padding-right:40px;
	text-align:right;
}
.post-link.prev-post-link  i {
	left:0;
	text-align:left;
}
.post-link.next-post-link  i {
	right:0;
	text-align:right;
}
.post-author {
	float:left;
	margin-right:20px;
	margin-top:10px;
}
.post-author img {
	width:40px;
	height:40px;
	border-radius:100%;
	float:left;
	margin-right:20px;
}
.post-author span {
	font-weight:600;
	position:relative;
	top:14px;
	color:$gray-5;
	font-size:12px;
}
.post-opt {
	padding-top:24px;
}
.post-opt , .post-opt li {
	float:left;
}
.post-opt li {
	margin-right:20px;
}
.post-opt li span , .post-opt li a  {
	color:$gray-4;
	font-weight:500;
	font-size:12px;
}
.post-opt li i {
	padding-right:6px;
}
.search-widget input {
	float:left;
	width:100%;
	border:none;
	background: $gray-8;
	height:50px;
	padding:0 80px 0 20px;
	z-index:1;
	border-radius:4px 0 0 4px;
}
.search-widget .search-submit{
	position:absolute;
	top:0;
	right:0;
	width:80px;
	height:50px;
	line-height:50px;
	z-index:2;
	color:$white;
	border:none;
	cursor:pointer;
	border-radius:0 4px 4px 0 ;
}
.jr-insta-thumb {
	float:left;
	width:100%;
	margin-bottom:15px;
}
.jr-insta-thumb ul {
	list-style:none;
}
.jr-insta-thumb li {
	float:left;
	width:33.3%;
	padding:5px;
	overflow:hidden;
}
.jr-insta-thumb li img {
	width:100%;
	height:auto;
	border-radius:6px;
}
.jr-insta-thumb li a , .post-link i {
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}
.jr-insta-thumb li a:hover {
	opacity:0.6;
}
.cat-item li{
	float:left;
	width:100%;
	padding-bottom: 8px;
	margin-bottom: 15px;
	text-align:left;
	position:relative;
}
.cat-item li:last-child {
	margin-bottom:0;
}
.cat-item li:before {
	content:'';
	position:absolute;
	bottom:6px;
	width:100px;
	height:1px;
	background:$gray;
	left:50%;
	margin-left:-25px;
}
.cat-item  li a{
	float:left;
	font-size:12px;
	color:$gray-5;
	font-weight:400;
}
.cat-item  li  span {
	float:right;
	color:$gray-4;
	font-size:12px;
}
.fixed-bg .overlay {
	opacity:0.6;
}
.mob-bg , .mobile-list-controls {
	display:none;
}
.vishidelem {
	display:block !important;
}
  
/*------responsive------------------------------------------------*/ 
@media only screen and  (max-width: 1224px) {
	.text-carousel .slick-item  {
		padding: 0 5px;
	}
	.col-list-wrap {
		width:800px;
	}
	.map-container.column-map {
		width: calc(100% - 800px);
	}
	.three-columns-grid .listing-item.has_one_column .geodir-category-listing {
		padding-left:13px !important;
	}
	.three-columns-grid .listing-item.has_one_column .geodir-category-img {
		top:12px; 
	}
}
@media only screen and  (max-width: 1064px) {
	.mobile-list-controls {
		display:block;
	}
	.col-list-wrap .mobile-list-controls {
		margin-top:20px;
	}
	.mlc {
		padding:15px 0;
		width:50%;
		color:$white;
		float:left;
		border-radius:0 4px 4px 0;
		font-size:12px;
		cursor:pointer;
	}
	.mlc:first-child {
		border-right:1px solid rgba($black,0.21);
		box-sizing:border-box;
		border-radius: 4px 0  0 4px;
	}
	.mlc.fl-wrap {
		width:100%;
		border-radius:4px;
	}
	.mlc i {
		padding-right:10px;
	}
	.col-list-search-input-item .quantity-item {
		margin-right:15px;
		width:auto;
	}
	.col-list-search-input-item {
		margin-bottom:15px;
	}
	.fixed-mobile {
		position:fixed !important;
		top:110px !important;
	}
	.map-close {
		display:block;
	}
	.nav-button-wrap , .mob-bg {
		display: block;
	}

	.fixed-footer {
		position:relative !important;
		float:left;
	}
	video.bgvid , .process-item:before , .back-to-filters {
		display:none;
	}
	.process-item {
		margin-bottom:20px;
	}
	.main-menu {
		width:100%;
		z-index:50;
		padding:15px 10px ;
		background:$white;
		border-bottom-right-radius:6px;
		border-bottom-left-radius:6px;
		border-right:1px solid $gray;
		border-bottom:1px solid $gray;
		visibility:hidden;
		opacity:0;
	}
	.main-menu nav{
		float: none;
	}
	.vismobmenu {
		opacity:1;
		visibility:visible;
	}
	.menusb {
		display:block;
		float:left;
		position:relative;
		width:100%;
		overflow:hidden;
	}
	.main-menu .menusb ul  {
		display:flex;
		flex-direction: column;
		align-items: center;
		position:relative;
	}
	.menusb li {
		display: inline-block;
		margin: 0;
		width:100%;
		position:relative;
		overflow:hidden;
		padding:0 20px;
	}
	.menusb li i {
		position:absolute;
		right:18px;
		top:10px;
	}
	.menusb  a {
		float:left;
		width:100%;
		padding:8px 5px;
		position:relative;
		font-weight: 600;
		font-size: 12px;
		color: $gray-3;
		line-height:14px;
		text-align:left;
	}
	.menusb  a i{
		transform: rotate(-90deg);
	}
	.menusb a.back{
		padding-left:24px;
	}
	.menusb a.back:before{
		font-family: Font Awesome\ 5 Pro;
		content: "\f177";
		font-size:14px;
	}
	.main-header .container {
		width:100%;
	}
	.home-grid .gallery-item {
		width:50%;
	}
	.home-grid .gallery-item .listing-counter {
		top:20px;
		left:15px;
	}
	.home-grid .gallery-item.gallery-item-second .listing-counter {
		left: inherit;
		right:15px;
		z-index:100;
		top:10px;
	}
	.home-grid .gallery-item  .listing-item-cat{
		padding: 30px 15px 10px;
	}
	section.hero-section  {
		padding:40px 0 80px 0;
	}
	.light-carousel-wrap , .fw-col-list-wrap .list-main-wrap-opt .list-main-wrap-title.col-title  {
		margin-top:30px;
	}
	.single-facts .inline-facts-wrap {
		width:100%;
		padding:5px 0;
	}
	.collage-image {
		margin-top:40px;
		float:left;
	}
	.images-collage-title  {
		left:10px;
	}
	.pad-top-column-text {
		padding:0;
	}
	.footer-carousel-title {
		top:0;
	}
	.footer-social {
		margin-bottom:30px;
	}
	.contact-btn , .mar-bot-cont {
		margin-top:0;
		margin-bottom:20px;
	}
	.subfooter-lang {
		float:left;
		width:100%;
		margin:15px 0;
	}
	.subfooter-lang-tooltip  {
		right:inherit;
		left:0;
		width:200px;
	}	
	.subfooter-nav {
		float:left;
		margin-right:0;
	}
	.card-post , .video-box  , .team-box , .tags-stylwrap a , .price-item   {
		margin-bottom:15px;
	}
	.best-price {
		top: 0;
		transform: scale(1.0);
	}
	.pricing-wrap {
		margin:0;
	}
	.team-box , .three-columns-grid .listing-item {
		width: 50%;
	}
	.success-table-header a {
		top:0;
		margin-top:15px;
		float:left;
	}
	.success-table-header p , #footer-twiit p.tweet  , .contact-btn  {
		width:100%;
	}
	.dasboard-wrap {
		padding-left: 0;
	}
	.dasboard-sidebar {
		position: relative;
		float:left;
		width: 100%;
		z-index: 10;
		top: 0;
		margin-bottom:20px;
	}
	.dasboard-avatar {
		width:30%;
		float:left;
	}
	.dasboard-sidebar-item   {
		width:70%;
	}
	.dasboard-sidebar-item {
		padding:0 10px 0 0;
		text-align:left;
	}
	.dasboard-sidebar-item:before {
		left:0;
		margin-left:0;
	}
	.log-out-btn  {
		background: $yellow !important
	}
	.ed-btn  {
		float:left;
	}
	.flat-header {
		padding-top: 140px;
	}
	.dasboard-sidebar-content {
		padding: 20px 0 44px 0;
	}
	.dasboard-sidebar-content:before {
		position: absolute;
		font-family: Font Awesome\ 5 Pro;
		content: "\f085";
		color: $white;
		right:70px;
		top:70px;
		opacity:0.4;
		font-size:54px;
	}
	.header-search-button {
		margin-top:10px;
	}
	.col-list-wrap  , .search-input-item , .search-input-item.midd-input , .search-input-item.small-input , #footer-twiit .timePosted a , .price-item , .three-columns-grid .listing-item.has_one_column {
		width:100%;
	}
	.list-single-main-container {
		padding-left:0;
	}
	.fixed-scroll-column {
		position:relative !important;
		float:left;
		left:0;
		width:100%;
		top:-20px;
	}
	.list-single-hero-rating  {
		float:left;
		margin-top:20px;
	}
	.list-single-hero-links {
		float:left;
	}
	.lisd-link {
		margin-left:0;
		margin-right:10px;
	}
	.list-single-hero-rating .score {
		float:right;
		text-align: left;
		padding-right:0;
		padding-left:15px;
	}
	.list-single-hero {
		padding: 100px 0 0;
	}
	.fc-button  {
		margin-right:5px;
	}
	.fc-button span {
		left:0;
		top:-60px;
	}
	.fixed-scroll-column-share-container {
		position:absolute;
		top:70px;
		left:0;
		background:$blue;
		padding-left:20px;
		width:180px;
		border-radius:6px;
	}
	.share-container .share-icon  {
		float:left;
		width: 20px;
		height: 20px;
		line-height: 20px;
		margin-right:6px;
	}
	.list-single-carousel-wrap , .fw-carousel .slick-slide-item {
		height:400px !important;
	}
	.booking-modal-content {
		padding-right:0;
	}
	.booking-modal-info {
		position: relative;
		height: auto;
		width:100%;
		float:left;
		margin-bottom:30px;
	}
}
@media only screen and  (max-width: 768px) {



	.listing-item.has_one_column .geodir-category-img {
		width:100%;

	}
	.listing-item.has_one_column .geodir-category-content {
		width:100%;
	}

	.home-grid .gallery-item  , .article-masonry , .main-search-input-item , .main-search-button  , .listing-item ,  .rooms-media , .rooms-details  {
		width:100%;
	}
	.show-currency-tooltip , .review-comments-avatar:before ,  .grid-opt , .scroll-nav{
		display: none;
	}	
	.show-search-button {
		width: 120px;
	}
	.add-hotel {
		padding: 0 30px;
	}
	.show-reg-form {
		margin-left: 15px;
		margin-right:20px;
	}
	.header-search-input-item  {
		margin-bottom:15px;
	}
	.header-search-input-item .quantity-item, .col-list-search-input-item .quantity-item {
		width: auto;
	}
	.header-search-button {
		top:5px;
	}
	.header-search {
		padding: 20px 30px 10px;
	}
	.main-search-input-item {
		margin-bottom:10px;
	}
	.main-search-input-item input , .main-search-input-item , .main-search-button  {
		border-radius:6px;
	}
	.main-search-input {
		padding:0;
		background:rgba($white,0.3);
	}
	.main-search-button  , .scroll-nav-wrapper .show-hidden-map{
		position:relative;
		float:left;
	}
	.qty-dropdown {
		z-index:100;
	}
	.home-intro h2  {
		font-size:34px;
	}
	.list-carousel .sw-btn{
		top:26%;
	}
	.light-carousel .slick-slide-item {
		padding:10px 0;
	}
	.light-carousel .slick-slide-item  .hotel-card {
		padding:0 10px;
	}
	.text-carousel .slick-item  {
		padding: 0 25px 0 5px;
	}
	.slider-container .slider-item{
		padding:80px 0 100px;
	}
	.slider-container-wrap .sw-btn {
		top:100%;
	}
	.reviews-comments-item {
		padding: 0 0 30px 0;
	}
	.review-comments-avatar {
		top: -25px;
		left: 25px;
		z-index:30;
		width:50px;
		height:50px;
	}
	.review-comments-avatar img {
		width:50px;
		height:50px;
	}
	.post-nav a{
		margin-bottom:20px;
 
	}
	#progressbar li{
		width:48%;
		margin-bottom:10px;
	}
	.ed-btn {
		margin-left:30%;
	}
	.listing-features li , .list-single-facts .inline-facts-wrap  {
		width:50%;
	}
	.rooms-details  {
		padding-left:0;
	}
	.list-single-hero-header .list-single-hero-price  , .rooms-details{
		margin-top:15px;
	}
}
@media only screen and  (max-width: 540px) {
	.home-btn , .show-search-button span{
		display:none;
	}
	.nav-button-wrap   {
		margin-left:10px;
	}
	.show-search-button {
		width: 60px;
	}
	.show-search-button i {
		padding-left: 0;
 
	}
	.home-intro h2 , .home-intro-card h3   , section.parallax-section .section-title.big-title h2  , .list-single-hero-title h2 {
		font-size:24px;
	}
	section.hero-section  {
		padding:50px 0 100px;
	}
	.list-carousel {
		padding: 0;
	}
	.collage-image .main-collage-image , .collage-image-input {
		right:10px;
	}
	.controls-mapwn {
		width:250px;
		padding: 0 20px;
		height:43px;
		line-height:43px;
	}
	.map-modal-item , .main-register-holder  {
		padding:0 10px;
	}
	.daterangepicker {
		width: 303px !important;
	}
	.team-box  , #progressbar li , .previous-form  , .next-form , .list-single-header-contacts li {
		width: 100%;
	}
	.list-single-header-contacts li {
		text-align:left;
		padding:5px 0;
	}
	.next-form   , .list-single-hero-price {
		float: left;
		margin-top:15px;
	}
	.success-table-header {
		padding: 10px 0 0 0;
	}
	.success-table-header i.decsth {
		position:relative;
		float:left;
		margin-bottom:10px;
	}
	.map-view-wrap {
		position:relative;
		height:auto;
		float:left;
	}
	.map-view-wrap_item  {
		top: 0;
	}
	.map-view-wrap_item , .dasboard-avatar , .dasboard-sidebar-item , .dashboard-message-avatar , .price-opt  , .half-tags  , .three-columns-grid .listing-item , .list-single-main-media .gallery-item  , .listing-features li ,   .list-single-facts .inline-facts-wrap , .review-total , .review-range-container , .facilities-list .btn , .review-score-detail , .review-score-total   {
		width: 100%;
	}
	.facilities-list .btn {
		margin-top:40px;
	}
	.review-score-total {
		margin-bottom:10px;
	}
	.dasboard-avatar , .review-range-container , .review-score-detail  {
		padding:0;
	}
	.dasboard-avatar img {
		width:80px;
		height:80px;
	}
	.list-single-facts .inline-facts-wrap {
		border-top:1px solid $gray-8;
		border-left:none;
	}
	.dasboard-sidebar-item {
		padding:0 10px 0 0;
		text-align: center;
	}
	.dasboard-sidebar-item:before {
		left:50%;
		margin-left:-25px;
	}
	.dasboard-sidebar-content {
		padding: 40px 0 44px 0;
	}
	.ed-btn  {
		float: none;
	}
	.tfp-btn {
		top: -70px;
		right:inherit;
		left:0;
 
	}
	.tfp-det {
		top:-30px;
	}
	.tfp-det-btn {
		float:none;
		display:inline-block;
	}
	.dasboard-sidebar-content:before {
		top:20px;
		right:20px;
	}
	.ed-btn {
		margin-left:0;
	}
	.dasboard-menu  , .dasboard-menu li{
		width:100%;
	}
	.dasboard-menu-btn {
		display:block;
	}
	.dasboard-menu ul.dasboard-menu-wrap {
		display:none;
		background:$white;
		float:left;
		width:100%;
		padding:10px 20px;
		margin-bottom:20px;
	}
	.dasboard-menu li ul {
		position: relative;
		top:0;
		background: none;
		padding:0;
		width: auto;
		z-index:2;
		border: none;
		opacity:1;
		display:block;
		visibility: visible;
		float:left;
		width:100%;
	}
	.dasboard-menu li ul li {
		float:left;
		width:100%;
		padding:0;
		position:relative;
	}
	.dasboard-menu li ul li span {
		position:absolute;
		top:50%;
		right:0;
		width:16px;
		height:16px;
		margin-top:-8px;
		line-height:16px;
		border-radius:100%;
		font-size:9px;
		color:$white;
	}
	.dasboard-menu li a i {
		display:none;
	}
	.dasboard-menu li a {
		padding:10px 0 !important;
		background:none !important;
		color:$gray-5 !important;
	}
	.dasboard-menu li a.user-profile-act , .dasboard-menu li a:hover {
		color:$secondary !important;
		box-shadow:none;
	}
	#myChartLegend  {
		float:left;
		margin-top:10px;
	}
	#myChartLegend li {
		margin-left:0;
		margin-right:10px;
	}
	.dashboard-message-text {
		margin-left:15px;
	}
	.new-dashboard-item {
		padding:6px 10px;
		right:15px;
	}
	.dasboard-menu {
		top:0;
	}
	.dashboard-message-text {
		margin-left: 0;
		text-align: left;
		padding-left: 0;
	}
	.dashboard-message-avatar  img , .review-total {
		float:left;
		margin-bottom:20px;
	}
	.chat-message , .chat-message.chat-message_user .massage-date  {
		padding-left:0;
		padding-right:0 !important;
	} 
	.chat-message .dashboard-message-avatar {
		position:relative;
		top:0;
	}
	.chat-message .dashboard-message-avatar span {
		float:left;
		margin: 10px 0 0 12px;
	}
	.chat-message.chat-message_user .massage-date    {
		float:left;
	}
	.massage-date{
		margin:10px 0;
	}
	.chat-message p:before  {
		display:none;
	}
	.map-close  {
		width:43px;
		height:43px;
		left:260px;
		line-height:43px;
		font-size:12px;
	}
	.list-single-carousel-wrap , .fw-carousel .slick-slide-item {
		height:300px !important;
	}
	.to-top {
		right: 10px;
	}
	.ajax-modal-container {
		width: 500px;
	}
	.ajax-modal-details {
		padding: 20px 25px;
	}
	.ajax-modal-list li {
		width:50%;
	}
	.error-wrap h2 {
		font-size:74px;
	}	
}
@media only screen and  (max-width: 420px) {


	.show-reg-form {
		margin-right:30px;
	}
	.wishlist-wrap {
		width:300px;
		padding: 25px 10px 0;
	}
	.header-user-name {
		font-size: 11px;
	}
	.header-user-name:before{
		bottom:-1px;
		right:-20px
	}
	.header-user-menu {
		margin-right: 30px;
	}
	.ajax-modal-container {
		width: 400px;
	}	
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
	.bg {
		background-attachment: scroll !important;
		-webkit-background-size:cover;
		background-position:center;
	}
}