@import './assets/styles/template/reset.css';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/utilities/api';
@import './assets/styles/template/plugins.css';
@import './assets/styles/template/style.css';
@import './assets/styles/template/color';
@import './assets/styles/project';
@import 'react-toastify/dist/ReactToastify.css';
