/* stylelint-disable color-hex-case */
$blue: #18458b;
$blue-2: #334e6f;
$blue-3: #2c3b5a;
$blue-4: #24324f;
$blue-light: #566985;
$blue-dark: #061b41;
$primary: $blue;
$secondary: #3aaced;
$color-secondary-gradient: #49ceff;
$yellow: #f9b90f;
$gray: #eeeeee;
$gray-dark: #878c9f;
$black: #000000;
$white: #ffffff;
$gray-light: #f7f9fb;
$gray-2: #cccccc;
$gray-3: #50596e;
$gray-4: #999999;
$gray-5: #666666;
$gray-6: #888888;
$gray-7: #f9f9f9;
$gray-8: #ecf6f8;
$gray-9: #494949;
$gray-10: #adc7dd;
$gray-11: #d0dbdb;
$gray-12: #454545;
$gray-13: #333333;
$gray-14: #bdcbdb;
$gray-15: #888da0;
$gray-16: #dddddd;
$gray-17: #d9d9d9;
$gray-18: #f3f3f3;
$green-light: #5ecfb1;
$red: #ff0000;

/* Social */
$twitter: #55acee;
$paypal: #205da5;
$facebook: #3b5998;

/* Table */
$table-striped-bg-factor: 0.05;
$table-striped-bg: rgba($secondary, $table-striped-bg-factor) !default;
$placeholder: hsl(0deg, 0%, 50%);
