/* stylelint-disable no-descending-specificity */
/* stylelint-disable color-hex-case */
@import './variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.others {
  padding-top: 5px;
}

.others-list-item {
  margin-bottom: 8px;
  text-align: left;
}

.others-link {
  color: $white;
  text-transform: uppercase;
}

.grid-item-holder {
  height: 22vw;
  background-position: center;
}

.geodir-category-listing {
  margin-bottom: 20px;
}

.category-price-desc {
  display: flex;
  flex-direction: row;
  line-height: 14px;
}

.category-price-desc span:nth-child(1) {
  margin-right: 5px;
  color: $gray-4;
  font-size: 11px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
}

.category-price-desc span:nth-child(2) {
  color: $secondary;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.divider-grey {
  margin: 15px 0;
  border-top: 1px solid $gray;
}

.event-title {
  margin-top: 15px;
  color: $primary;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  text-align: left;
}

.event-title-secondary {
  color: $secondary;
}

.event-desc {
  padding-bottom: 10px;
  color: #878c9f;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.list-item {
  position: relative;
  height: 22vw;
  min-height: 250px;
  max-height: 400px;
  overflow: hidden;
  border-radius: 9px;
  cursor: default;

  .list-item-image {
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:hover .list-item-image {
    transform: scale(1.03);
  }
}

.rc-input-number {
  display: inline-block;
  width: 100px;
  height: 42px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  transition: all 0.3s;
  border: 1px solid $gray;
  border-radius: 4px;
  font-size: 12px;
  line-height: 26px;
  vertical-align: middle;
}

.rc-input-number-out-of-range input {
  color: $red;
}

.rc-input-number-handler {
  display: block;
  height: 21px;
  overflow: hidden;
  background-color: $gray-light;
  line-height: 21px;
  text-align: center;
  touch-action: none;
}

.rc-input-number-handler-active {
  background: $gray-16;
}

.rc-input-number-handler-up-inner,
.rc-input-number-handler-down-inner {
  color: $gray-5;
  user-select: none;
}

.rc-input-number-disabled:hover {
  border-color: $gray-17;
}

.rc-input-number-disabled:hover .rc-input-number-handler-up,
.rc-input-number-disabled:hover .rc-input-number-handler-wrap {
  border-color: $gray-17;
}

.rc-input-number-input-wrap {
  height: 100%;
  overflow: hidden;
}

.rc-input-number-input {
  width: 100%;
  height: 100%;
  padding: 0;
  transition: all 0.3s ease;
  transition: all 0.3s;
  border: 0;
  border-radius: 6px;
  outline: 0;
  color: $gray-5;
  line-height: 26px;
  text-align: center;
  appearance: textfield;
}

.rc-input-number-handler-wrap {
  width: 20px;
  height: 100%;
  float: right;
  transition: all 0.3s;
}

.rc-input-number-handler-up {
  padding-top: 1px;
  transition: all 0.3s;
  border-bottom: 1px solid $gray;
  border-left: 1px solid $gray;
}

.rc-input-number-handler-up-inner::after {
  content: '+';
}

.rc-input-number-handler-down {
  transition: all 0.3s;
  border-left: 1px solid $gray;
}

.rc-input-number-handler-down-inner::after {
  content: '-';
}

.rc-input-number-handler-down-disabled,
.rc-input-number-handler-up-disabled {
  opacity: 0.3;
}

.rc-input-number-handler-down-disabled:hover,
.rc-input-number-handler-up-disabled:hover {
  border-color: $gray-17;
  color: $gray-4;
}

.rc-input-number-disabled .rc-input-number-input {
  opacity: 0.72;
  background-color: $gray-18;
  cursor: not-allowed;
}

.rc-input-number-disabled .rc-input-number-handler {
  opacity: 0.3;
}

.rc-input-number-disabled .rc-input-number-handler:hover {
  border-color: $gray-17;
  color: $gray-4;
}

.react-select {
  .react-select__menu {
    z-index: 1000;
  }

  .react-select__placeholder {
    text-align: left;
  }

  .react-select__control {
    height: auto;
    min-height: 42px;
    background-color: $gray-light;

    &--is-focused {
      /* stylelint-disable-next-line */
      box-shadow: none;
    }
  }

  .react-select__value-container {
    padding-left: 10px;
  }

  .react-select__icon {
    display: flex;
  }

  .react-select__single-value,
  .react-select__group-heading,
  .react-select__option {
    text-align: left;
  }
}

.react-select-pure {
  div.react-select__control {
    min-height: 50px;
    /* stylelint-disable-next-line */
    border: none;
    background-color: $white;
  }

  .react-select__indicator-separator {
    display: none;
  }
}

.rommandnumbers .form-check {
  align-items: center;
}

.rommandnumbers .rc-input-number {
  margin-bottom: 15px;
}

/* .rommandnumbers .col-list-search-input-item {
  margin-top: 14px;
}
 */
.room-and-numbers-head-check div {
  margin-right: 10px;
}

.rommandnumbers input[type='radio'] {
  margin-right: 5px;
  margin-left: 5px;
}

input[type='radio']::before {
  content: '';
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  border-radius: 50%;
  background-color: $secondary;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

.radio-wrapper {
  display: flex;

  &,
  label,
  .input-radio {
    cursor: pointer;
  }
}

.input-radio {
  border: 2px solid $gray-2;
  border-radius: 100%;
}

.detail-container {
  display: grid;
  gap: 5px;
  grid-template-columns: auto auto auto auto;

  img {
    object-fit: cover;
    width: 100%;
    height: 200px;
    cursor: pointer;
  }
}

.detail-1 {
  grid-area: a;
}

.detail-2 {
  grid-area: b;
}

.detail-3 {
  grid-area: c;
}

.detail-4 {
  grid-area: d;
}

.detail-5 {
  grid-area: e;
}

.detail-6 {
  grid-area: f;
}

.parallax-large {
  width: 100%;
  max-height: 510px;
}

.text-yellow {
  color: $yellow;
}

.text-blue {
  color: $secondary;
}

.text-red {
  color: $red;
}

.text-white {
  /* stylelint-disable-next-line */
  color: $white;
}

.text-gray {
  /* stylelint-disable-next-line */
  color: $gray-5;
}

.cursor-pointer {
  cursor: pointer;
}

.listing-item.has_one_column {
  .geodir-category-listing {
    min-width: 400px;
  }
}

.details-table {
  th {
    background-color: $secondary;
    color: $white;
    font-size: 14px;
    vertical-align: middle;
  }

  td {
    vertical-align: middle;
  }
}

.h-small {
  height: unset;
}

.mapLink {
  display: block;
  box-sizing: border-box;
  height: 80px;
  margin-bottom: 20px;
  padding: 25px 0;
  background: url('../images/map_background.png') top center no-repeat;
  text-align: center;
}

.map-box {
  min-height: 320px;
  overflow: hidden;
}

.map-show-button {
  position: relative;
  bottom: 5px;
  height: 42px;
  padding: 0 20px;
  border: none;
  border-radius: 4px;
  outline: none;
  color: #ffffff;
  line-height: 42px;
  cursor: pointer;
}

.map-list-overflow {
  height: calc(100vh - 154px);
  overflow: auto;
}

@media only screen and (max-width: 1064px) {
  .detail-container {
    grid-template:
      'a b' 1fr
      'd d' 1fr
      'd d' 1fr;
  }

  .is-mobile-visible {
    display: block;
    visibility: visible;
    position: static;
    width: 100%;
    opacity: 1;

    ul {
      /* stylelint-disable-next-line */
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        display: inline-block;
        float: none;

        a {
          color: $black;
        }
      }
    }
  }
}

.scroll-nav-wrapper {
  max-width: 100%;
  overflow: auto;
}

.ask-callback {
  margin-top: 40px;
}

.icon-title {
  color: $gray-4;
  font-size: 10px;
}

.overflow-x-auto {
  margin-top: -25px;
  /* stylelint-disable-next-line */
  overflow-x: auto;
}

.facility-count,
.text-capacity {
  color: #999999;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.dotted-separator {
  padding-top: 15px;
  border-top: 1px dotted #cccccc;
}

.text-capacity {
  margin-top: 8px;
}

.caret-down::before {
  color: $primary;
}

.box-widget-item-header-hidden {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

@media only screen and (max-width: 768px) {
  .listing-item.has_one_column {
    .geodir-category-listing {
      min-width: unset;
      padding-right: 13px;
    }
  }

  .has_one_column {
    .geodir-category-listing {
      /* stylelint-disable-next-line declaration-no-important */
      flex-direction: column;
    }

    .geodir-category-img {
      margin-bottom: 25px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .home-search {
    margin: 10px 18px;
    padding: 5px;
  }

  .detail-container {
    grid-template:
      'a' 1fr
      'b' 1fr
      'c' 1fr;

    img {
      height: 250px;
    }
  }

  .main-search-input {
    flex-direction: column;
    height: auto;
    /* stylelint-disable-next-line declaration-no-important */
    padding-left: 0;

    .main-search-input-item:last-child {
      margin-bottom: 0;
    }
  }

  .logo-holder {
    height: 28px;
    margin-top: 5px;
  }
}

.has_one_column {
  .geodir-category-listing {
    display: flex;
    flex-direction: row;
  }

  .geodir-category-img {
    margin-bottom: 25px;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.highlited-description {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-inline-box;
  width: 250px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.highlited-place-name {
  min-height: 42px;
}

.home-search {
  border: 1px solid #878c9f;
  border-radius: 8px;
}

.home-header {
  padding: 30px 0;
}

.home-header h2 {
  color: $blue;
}

.home-header h3 {
  color: $gray-dark;
}

.search-item label {
  color: $placeholder;
}

.header-offer button {
  margin-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
  border: none;
  border-radius: 4px;
  background-color: $yellow;
  color: $white;
  line-height: 42px;
  cursor: pointer;
}

.header-trash {
  color: #cc4343;
  font-size: 14px;
  cursor: pointer;
}

.time-line-icon {
  font-size: 50px;
}

.color-bg .process-item {
  min-height: 225px;
}

.count-number {
  position: absolute;
  z-index: 2;
  top: 19px;
  width: 15px;
  height: 15px;
  margin-left: 8px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 10%);
  color: #ffffff;
  font-size: 10px;
  line-height: 15px;
}

.form-required::after {
  content: ' *';
  color: rgb(255, 0, 0);
}

.popup-title {
  margin: 0 0 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eeeeee;

  h3 {
    color: $blue;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }
}

.error {
  color: rgb(255, 0, 0);
}

.ready {
  color: $blue;
}

.verify-text {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.special-offer-container {
  position: absolute;
  z-index: 4;
  bottom: 0;

  .offer-name {
    margin: 0 3px 7px 10px;
    float: left;
    color: $white;
    font-size: 13px;
  }
}

.special-offer-date {
  color: $gray-4;
  line-height: 1.1;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: -20px;
}

.home-desc-title {
  color: #18458b;
  font-size: 16px;
  font-weight: 600;
}

.banner-img {
  height: 414px;
  border-radius: 10px;
}

.banner-img img {
  height: 100%;
}

.listing-banner-img {
  height: 649px;
  border-radius: 10px;
}

.listing-banner-img img {
  height: 100%;
}

.listing-banner-img.has_one_column_banner {
  /* stylelint-disable-next-line declaration-no-important */
  width: 100%;
  height: 348px;
  border-radius: 10px;
}

.fix-position {
  position: fixed;
  z-index: 999;
}

.element {
  height: 1000px;
  padding-top: 55px;
  padding-left: 10px;
  border-top: 1px solid;
  border-color: #00000000;
  background-color: #ededed;
  font-size: 45px;
}

.element.no-padding {
  padding-top: 0;
}

.active div {
  background-color: $secondary;
  color: #ffffff;
}

.contact-button div {
  background-color: $yellow;
  color: #ffffff;
}

.advertisement {
  padding: 30px;
  background-color: #ffffff;
  text-align: left;
}

.printable {
  display: none;
}

@media print {
  .printable {
    display: unset;
  }

  body {
    background-color: $white;
    font-size: 10px;
  }

  .filter-tags input {
    width: 10px;
    height: 10px;
  }

  .react-select .react-select__control {
    min-height: 10px;
  }

  .table > :not(caption) > * > * {
    padding: 3px 0;
  }
}

.detail-icons th div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listing-trash {
  align-self: center;
  font-size: 20px;
  padding-left: 15px;
  color: $yellow;
  cursor: pointer;
}
